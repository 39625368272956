import React, {useState} from 'react';
import {MemberIdentification} from "./MemberIdentification";
import logo from "../assets/TributeWebHeader.png";
import {NavFooter} from "./NavFooter";
import {FormID} from "../FormID";
import {RequiredStar} from "./RequiredStar";
import Cleave from "cleave.js/react";
import medicareCardOutline from "../assets/medicare-card-sample-outline.jpg";
import {PopoverBody, UncontrolledPopover} from "reactstrap";

export function Application(props) {
    const display = props.step === 1 ? '' : 'hidden';
    const printSOA = props.isAgent === 'yes' ? 'break-before' : '';
    const [memberInfoDisabled, setMemberInfoDisabled] = useState(true);
    const [disableVerify, setDisableVerify] = useState(true);
    const [showSpinner, setShowSpinner] = useState(false);
    const isnpPlan = 'I-SNP';
    const dsnpPlan = 'D-SNP';
    function medicareNumberIsValid(value) {
        const nonZero = "([1-9])";
        const numeric = "([0-9])";
        const alpha = "((?=[^SLOBIZ])[A-Z])";
        const alphaNumeric = "((?=[^SLOBIZ])[A-Z0-9])";
        const r = new RegExp(`^${nonZero}${alpha}${alphaNumeric}${numeric}${alpha}${alphaNumeric}${numeric}${alpha}${alpha}${numeric}${numeric}`)
        return r.test(value);
    }

    function handleMedicareNumberChange(e) {
        const rawValue = e.target.rawValue;
        const input = document.getElementById('medicareNumber');
        if (medicareNumberIsValid(rawValue)) {
            input.setCustomValidity('');
            setDisableVerify(false);
        } else {
            input.setCustomValidity('Medicare Number is not valid');
        }
        if (input.reportValidity()) {
            props.handleCoverageChange(e);
        }
        return false;
    }

    function verifyMbi() {
        const model = {
            Mbi: props.medicareCoverage.medicareNumber,
            MaPlanId: props.maPlanId,
        };

        setShowSpinner(true);
        props.fetchMemberEnrollmentExists(model)
            .then(data => {
                if (data === true) {
                    props.onChange(7); // set the step value to 'Already Exists'
                }
                setMemberInfoDisabled(false);
            });
    }
    
    function Spinner(props) {
        return <span className={"spinner-border spinner-border-sm"}> </span>;
    }

    function togglePlans() {
        if (props.planChoice === 'isnp') {
            props.setPlanChoice('dsnp');
        }
        if (props.planChoice === 'dsnp') {
            props.setPlanChoice('isnp');
        }
    }

    return (
        <div className={`container ${display} print ${printSOA}`} id="application">
            <form method="post" id="formMember" name="formMember">
                <div className="row mt-5 hidden print">
                    <div className="col">
                        <img src={logo} alt=""/>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col">
                        <h1 className="brand-color-lightgray">{props.textModel.name ?? 'title'}</h1>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-sm-12 mt-3">
                        {props.textModel.description}
                        <br/>
                        <a href={'#'} onClick={togglePlans} className="no-print" data-html2canvas-ignore="true" 
                           style={{textDecoration: 'none',color: '#1b6ec2'}}>Switch to {props.planChoice === 'isnp' ? dsnpPlan : isnpPlan}</a>
                        <br/>
                        <hr/><small className="required mt-4 no-print" data-html2canvas-ignore="true">*
                        Required.</small>
                        <div className="row question">
                            <div className="col-sm-12 col-md-4">
                                <label htmlFor="medicareNumber">
                                    <RequiredStar data-html2canvas-ignore="true"/>Please enter your&nbsp;
                                    <a href={"#"} id={"whatIsThis"} className={"medicare-link"}>Medicare Number</a>
                                </label>
                                <Cleave onChange={handleMedicareNumberChange}
                                        options={{
                                            delimiter: '-',
                                            blocks: [4, 3, 4],
                                            uppercase: true
                                        }}
                                        className="form-control"
                                        id="medicareNumber"
                                        name="medicareNumber"
                                        required="required"
                                        constructor={''}
                                        disabled={!memberInfoDisabled}
                                />
                            </div>
                            <div className="col-sm-12 col-md-4 button-align-bottom">
                                {
                                    memberInfoDisabled &&
                                    <button type={"button"} className={"btn btn-primary"} onClick={verifyMbi} disabled={disableVerify}>
                                        {showSpinner && <Spinner/>} Verify
                                    </button>
                                    
                                }
                                {
                                    !memberInfoDisabled &&
                                    <button type={"button"} className={"btn btn-success"} disabled={!memberInfoDisabled}>
                                        Valid
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <MemberIdentification facilities={props.facilities}
                                              member={props.member}
                                              memberAddress={props.memberAddress}
                                              mailingAddress={props.mailingAddress}
                                              onChange={props.handleMemberChange}
                                              disabled={memberInfoDisabled}/>
                    </div>
                </div>
                <NavFooter step={props.step}
                           lastStep={props.lastStep}
                           display={props.step === 1}
                           onChange={props.onChange}/>
                <FormID/>
            </form>
            <UncontrolledPopover placement={"top"} target={"whatIsThis"} trigger={"hover"}>
                <PopoverBody>
                    <div>
                        <img src={medicareCardOutline} className="medicare-card" alt="Medicare Card Example"/>
                    </div>
                </PopoverBody>
            </UncontrolledPopover>
            <img src={medicareCardOutline} style={{contentVisibility: "hidden"}}/>
        </div>

    )
}