import React from "react";

// Make this a part of a HOC so that it can be wrapped.
// https://stackoverflow.com/questions/57852370/hoc-functional-component

export function YesNo(props) {

    const {dataToggleYes = '', dataTargetYes = '', dataToggleNo = '', dataTargetNo = ''} = props;
    return (
        <fieldset>
            <div style={{display: 'block'}}
                 id={props.name}
                 className="btn-group btn-group-toggle pr-3"
                 data-toggle="buttons">
                <label htmlFor={props.name + "Yes"} id={props.name + "YesLabel"}
                    className={"btn btn-secondary yesno " + (props.value === 'yes' ? 'active' : '') + (props.disabled === true ? ' disabled ' : '') + (props.size === 'small' ? ' btn-sm ' : '')}>
                    <input type="radio"
                           name={props.name}
                            id={props.name + "Yes"} 
                           value="yes"
                           checked={props.value === 'yes'}
                           onChange={props.onChange}
                           onClick={props.onClick}
                           required={props.required}
                           data-toggle={dataToggleYes}
                           data-target={dataTargetYes}
                           disabled={props.disabled}
                           aria-label={props.label || props.name} 
                           data-html2canvas-ignore="true"/>
                    Yes</label>

                <label htmlFor={props.name + "No"} id={props.name + "NoLabel"}
                    className={"btn btn-secondary yesno " + (props.value === 'no' ? 'active' : '') + (props.disabled === true ? ' disabled ' : '') + (props.size === 'small' ? ' btn-sm ' : '')}>
                    <input type="radio"
                           name={props.name}
                         id={props.name + "No"} 
                           value="no"
                           checked={props.value === 'no'}
                           onChange={props.onChange}
                           onClick={props.onClick}
                           data-toggle={dataToggleNo}
                           data-target={dataTargetNo}
                           disabled={props.disabled}
                           aria-label={props.label || props.name} 
                           data-html2canvas-ignore="true"/>
                    No</label>
            </div>
        </fieldset>
    );
}