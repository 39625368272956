import React, {Fragment, useEffect, useRef} from 'react';
import {SpinnerBasic} from "./Spinner-basic";
import {NavMenu} from "./NavMenu";
import {Application} from "./Application";
import {Coverage} from "./Coverage";
import {Premium} from "./Premium";
import {Confirmation} from "./Confirmation";
import {Complete} from "./Complete";
import {Optional} from "./Optional";
import '../custom.css';
import {Screen} from "./Screen";
import {Footer} from "./Footer";
import {CustomerSupport} from "./CustomerSupport";
import {AproposUnavailable} from "./AproposUnavailable";
import {SOAForm} from "./SOAForm/SOAForm";
import {SOAQualification} from "./SOAForm/SOAQualification";
import {ErrorOnPost} from "./ErrorOnPost";
import AlreadyInUse from "./AlreadyInUse";
import {SelfDirected} from "./SelfDirected/SelfDirected";

export function Home(props) {
    const scrollTopRef = useRef(null);

    useEffect(() => {
        scrollTopRef.current.scrollIntoView();
    }, [props.step]);

    const renderBody = () => {
        return (
            <Fragment>
                {props.isSubmitting && <SpinnerBasic/>}
                <main id={"content"} ref={scrollTopRef} className={"print"}>
                    <NavMenu step={props.step}/>

                    <AproposUnavailable step={props.step} loadError={props.loadError}/>
                    
                    <SelfDirected step={props.step}
                                  lastStep={props.lastStep}
                                  onChange={props.onChange}
                                  loadError={props.loadError}/>
                    
                    <Screen step={props.step}
                            lastStep={props.lastStep}
                            onChange={props.onChange}
                            loadError={props.loadError}
                            screen={props.screen}
                            handleScreenChange={props.handleScreenChange}
                            facilities={props.facilities}
                            soaForm={props.soaForm}
                            setScreenItem={props.setScreenItem}
                            isAgent={props.isAgent}/>

                    <Application step={props.step}
                                 lastStep={props.lastStep}
                                 facilities={props.facilities}
                                 onChange={props.onChange}
                                 textModel={props.textModel}
                                 member={props.member}
                                 memberAddress={props.memberAddress}
                                 mailingAddress={props.mailingAddress}
                                 handleMemberChange={props.handleMemberChange}
                                 isAgent={props.isAgent} 
                                 handleCoverageChange={props.handleCoverageChange} {...props}/>

                    <Coverage step={props.step}
                              lastStep={props.lastStep}
                              onChange={props.onChange}
                              handleCoverageChange={props.handleCoverageChange}
                              medicareCoverage={props.medicareCoverage}/>

                    <Premium step={props.step}
                             lastStep={props.lastStep}
                             onChange={props.onChange}
                             display={props.step === 3}
                             planChoice={props.planChoice}
                             premium={props.premium}
                             handlePremiumChange={props.handlePremiumChange} 
                             resetPremium={props.resetPremium} 
                             premiumPaymentOptions={props.premiumPaymentOptions}/>

                    <Optional step={props.step}
                              lastStep={props.lastStep}
                              onChange={props.onChange}
                              display={props.step === 4}
                              optionalInformation={props.optionalInformation}
                              handleOptionalChange={props.handleOptionalChange} 
                              races={props.races} 
                              ethnicities={props.ethnicities} 
                              handleEthnicityChange={props.handleEthnicityChange} 
                              handleRaceChange={props.handleRaceChange}/>

                    <Confirmation step={props.step}
                                  lastStep={props.lastStep}
                                  onChange={props.onChange}
                                  onFormSubmit={props.onFormSubmit}
                                  display={props.step === 5}
                                  confirmation={props.confirmation}
                                  handleAuthorizedRepChange={props.handleAuthorizedRepChange}
                                  handleConfirmationChange={props.handleConfirmationChange}
                                  member={props.member}
                                  authorizedRepresentative={props.authorizedRepresentative}
                                  isAuthRep={props.isAuthRep}
                                  authorizedRepresentativeRelationships={props.authorizedRepresentativeRelationships}
                                  soaForm={props.soaForm}
                                  isAgent={props.isAgent}
                                  handleSalesAgentEnteredChange={props.handleSalesAgentEnteredChange}
                                  handleSalesAgentAssistedChange={props.handleSalesAgentAssistedChange}
                                  enteringSalesAgentId={props.enteringSalesAgentId}
                                  handleSalesAgentIdChange={props.handleSalesAgentIdChange}
                                  handleTelephonicApplicationChange={props.handleTelephonicApplicationChange}
                                  isTelephonicApplication={props.isTelephonicApplication}
                                  salesAgentEntered={props.salesAgentEntered}
                                  handleApplicationFileChange={props.handleApplicationFileChange}
                                  handleAssistingAgentNameChange={props.handleAssistingAgentNameChange}
                                  assistingAgentName={props.assistingAgentName}
                    />

                    {props.isPostError === false &&
                    <Complete step={props.step}
                              lastStep={props.lastStep}
                              onChange={props.onChange}
                              confirmationNumber={props.confirmationNumber}
                              isPostError={props.isPostError}/>
                    }

                    {props.isPostError === true && 
                    <ErrorOnPost step={props.step}
                                 lastStep={props.lastStep}
                                 onChange={props.onChange}
                                 confirmationNumber={props.confirmationNumber}
                                 isPostError={props.isPostError}
                                 agentInfo={props.soaForm}/>
                    }
                    <CustomerSupport/>
                    <AlreadyInUse {...props} />
                </main>
                <Footer/>
            </Fragment>
        );
    }

    return (
        <Fragment>
            {props.loading === true
                ? <SpinnerBasic/>
                : renderBody()}
        </Fragment>
    );
}


