import React from "react";

export function AlternateFormatButtons(props) {
    return (
        <div style={{display: 'block'}}
             className="btn-group btn-group-toggle"
             data-toggle="buttons"
             aria-label={props.label || props.name} >

            <label className={"btn btn-secondary " + (props.optionalInformation.accessibleFormat === 'braille' ? 'active' : '')}>
                <input type="checkbox"
                       name="accessibleFormat"
                       id="braille"
                       value="braille"
                       checked={props.optionalInformation.accessibleFormat === 'braille'} 
                       onChange={props.handleOptionalChange} 
                       data-html2canvas-ignore="true"/>
                Braille</label>

            <label className={"btn btn-secondary " + (props.optionalInformation.accessibleFormat === 'largePrint' ? 'active' : '')}>
                <input type="checkbox"
                       name="accessibleFormat"
                       id="largePrint" 
                       value="largePrint"
                       checked={props.optionalInformation.accessibleFormat === 'largePrint'} 
                       onChange={props.handleOptionalChange}
                       data-html2canvas-ignore="true"/>
                Large Print</label>

            <label className={"btn btn-secondary " + (props.optionalInformation.accessibleFormat === 'audioCd' ? 'active' : '')}>
                <input type="checkbox"
                       name="accessibleFormat"
                       id="audioCd" 
                       value="audioCd"
                       checked={props.optionalInformation.accessibleFormat === 'audioCd'} 
                       onChange={props.handleOptionalChange}
                       data-html2canvas-ignore="true"/>
                Audio CD</label>

        </div>
    );
}