import React from "react";

export function PaymentOptions(props) {

    return (
        <section className={"print " + ((props.display) ? '' : 'hidden')} id="paymentOptions">
            <div className="row">
                <div className="col">
                    <select id={props.premiumPaymentOptions.id} 
                            name={'premiumPaymentOptionId'} 
                            value={props.premium.premiumPaymentOptionId} 
                            onChange={props.handlePremiumChange} 
                            className={"form-control"} 
                            required={true} 
                            style={{marginTop: '1em'}}>
                    {   
                        props.premiumPaymentOptions.map(x => <option key={x.id} value={x.id}>{x.code} - {x.description}</option>)
                    }
                    </select>
                </div>
            </div>
        </section>
    );
}