import React from "react";

export function GenderSelect(props) {

    const {dataToggleMale = '', dataTargetMale = '', dataToggleFemale = '', dataTargetFemale = ''} = props;
    return (
        <div style={{display: 'block'}}
             id={props.name}
             className="btn-group btn-group-toggle pr-3"
             data-toggle="buttons"
             aria-label={props.label || props.name}>
            <label className={"btn btn-secondary " + (props.value === 'M' ? 'active' : '')}>
                <input type="radio"
                       name={props.name}
                       id="male"
                       value="M"
                       checked={props.value === 'M'}
                       onChange={props.onChange}
                       onClick={props.onClick}
                       required={props.required}
                       data-toggle={dataToggleMale}
                       data-target={dataTargetMale} 
                       data-html2canvas-ignore="true"/>
                Male</label>

            <label className={"btn btn-secondary " + (props.value === 'F' ? 'active' : '')}>
                <input type="radio"
                       name={props.name}
                       id="female"
                       value="F"
                       checked={props.value === 'F'}
                       onChange={props.onChange}
                       onClick={props.onClick}
                       data-toggle={dataToggleFemale}
                       data-target={dataTargetFemale} 
                       data-html2canvas-ignore="true"/>
                Female</label>
        </div>
    );
}