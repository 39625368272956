import React from "react";
import {NavFooter} from "./NavFooter";
import '../custom.css';
import {PaymentOptions} from "./PaymentOptions";
import {FormID} from "../FormID";

export function Premium(props) {
    const display = props.step === 3 ? '' : 'hidden';

    return (
        <div className={`container ${display} print break-before`} id="premium">
            <form method="post" id="formPremium" name="formPremium">

                <div className="row mt-5 mb-4">
                    <div className="col">
                        <h1 className="brand-color-lightgray">Payment Options</h1>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-sm-6">
                        <p>You can pay your monthly plan premium (including any Late Enrollment Penalty that you
                            currently have or may owe) by mail each month. <br/><br/>Or you can choose to pay your
                            premium by
                            automatic deduction from your Social Security or Railroad Retirement Board (RRB) benefit
                            check each month.
                        </p>


                        <p className={"mt-4"}>
                            If you don’t select a payment option, you will get a bill each month.
                            Please select a premium payment option:
                        </p>

                        <PaymentOptions display={props.display}
                                        premium={props.premium}
                                        handlePremiumChange={props.handlePremiumChange}
                                        onChange={props.onChange}
                                        resetPremium={props.resetPremium} 
                                        premiumPaymentOptions={props.premiumPaymentOptions}/>
                    </div>
                    {props.planChoice === 'dsnp' &&
                        <div className={"col-sm-6"}>
                            <div id={"dsnp"}>
                                <div className={"card"}>
                                    <div className={"card-header"}>Tribute Advantage (HMO-POS D-SNP)</div>
                                    <div className={"card-body"}>
                                        <p className={"card-text"}>Based on the information you provided, you have both
                                            Medicare Part A and B and full-benefit Medicaid.</p>
                                        <p style={{
                                            fontSize: '1.2rem',
                                            fontWeight: '700',
                                            marginBottom: '0'
                                        }}>Premium</p>
                                        <p>$0 monthly</p>
                                        <p className={"card-text"}>Your premium includes coverage for medical services
                                            and
                                            prescription drugs.</p>
                                        <i>You must continue to pay your Medicare Part B premium to ensure your coverage
                                            continues.</i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {props.planChoice === 'isnp' &&
                        <div className={"col-sm-6"}>
                            <div id={"isnp"}>
                                <div className={"card"}>
                                    <div className={"card-header"}>Tribute Select (HMO-POS I-SNP)</div>
                                    <div className={"card-body"}>
                                        <p className={"card-text"}>Depending on level of assistance and if you have both
                                            Medicare Part A and B</p>
                                        <p style={{
                                            fontSize: '1.2rem',
                                            fontWeight: '700',
                                            marginBottom: '0'
                                        }}>Premium</p>
                                        <p>$0 - $35.70 monthly</p>
                                        <p className={"card-text"}>Your premium includes coverage for medical services
                                            and
                                            prescription drugs.</p>
                                        <i>You must continue to pay your Medicare Part B premium to ensure your coverage
                                            continues.</i>

                                        <table className={"table table-sm mt-4 mb-3"}>
                                            <thead className={"thead-light"}>
                                            <tr>
                                                <th scope={"col"}>Your level of help</th>
                                                <th scope={"col"}>Monthly Premium</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>100%</td>
                                                <td>$0</td>
                                            </tr>
                                            <tr>
                                                <td>0%</td>
                                                <td>$35.70</td>
                                            </tr>
                                            </tbody>
                                        </table>

                                        <p>If you are not getting extra help, you can see if you qualify by calling:</p>
                                        <ul className={"mr-3"}>
                                            <li>Medicare at 800-633-4227<br/>(24 hours a day / 7 days a week).</li>
                                            <li>Your state Medicaid office</li>
                                            <li>The Social Security Administration<br/>800-772-1213 (7 a.m. and 7 p.m.
                                                Monday through Friday)
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    }
                </div>
                <div className="row mt-1">
                    <div className="col-sm-12 mt-5">
                        <p><b>If you have to pay a Part D-Income Related Monthly Adjustment Amount (Part D-IRMAA), you
                            must pay this extra amount in addition to your plan premium.</b> The amount is usually taken
                            out
                            of your Social Security benefit, or you may get a bill from Medicare (or the RRB). DON’T pay
                            Tribute Health Plans the Part D-IRMAA.</p>
                    </div>
                </div>

                <NavFooter step={props.step}
                           lastStep={props.lastStep}
                           display={props.step === 3}
                           onChange={props.onChange}/>
                <FormID/>
            </form>
        </div>
    )
}