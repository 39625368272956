import React, {useCallback, useEffect, useState, useRef} from 'react';
import {Home} from './components/Home';
import './custom.css'
import {SpinnerBasic} from "./components/Spinner-basic";
import {
    authorizedRepresentativeModel,
    confirmationModel,
    coverageModel,
    memberModel,
    optionalModel,
    locationModel,
    premiumModel,
    screenModel,
    soaFormModel, raceModel,
} from "./models/models";
import {removeDashes} from "./utilities/utilities";
import {printPDF, blobToBase64} from "./utilities/printPDF";

export default function App(props) {
    const [step, setStep] = useState(0);
    const [lastStep, setLastStep] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isAuthRep, setIsAuthRep] = useState(false);
    const [maPlanId, setMaPlanId] = useState(0);
    const [facilities, setFacilities] = useState([]);
    const [screen, setScreen] = useState(screenModel);
    const [member, setMember] = useState(memberModel);
    const [medicareCoverage, setMedicareCoverage] = useState(coverageModel);
    const [premium, setPremium] = useState(premiumModel);
    const [optional, setOptional] = useState(optionalModel);
    const [confirmation, setConfirmation] = useState(confirmationModel);
    const [authorizedRepresentative, setAuthorizedRepresentative] = useState(authorizedRepresentativeModel);
    const [memberAddress, setMemberAddress] = useState(locationModel);
    const [mailingAddress, setMailingAddress] = useState(locationModel);
    const [planChoice, setPlanChoice] = useState('');
    const [textModel, setTextModel] = useState({});
    const [confirmationNumber, setConfirmationNumber] = useState('0');
    const [authorizedRepresentativeRelationships, setAuthorizedRepresentativeRelationships] = useState([
        'APS', 'ATY', 'BRO', 'CRG', 'DAD', 'DAU', 'DHS', 'DIL', 'GCH', 'GR', 'GRD', 'MOM', 'NCH', 'NN', 'OTH', 'PAL', 'PHY', 'POA', 'REL', 'REP', 'SCH', 'SIB', 'SIS', 'SON', 'SPO', 'SPS', 'SUR', 'UNK'
    ]);
    const [maPlans, setMaPlans] = useState([]);
    const [contractId, setContractId] = useState(0);
    const title = props.title;
    const isnpTextModel = {
        maPlanId: '',
        maPlanTypeId: 2,
        name: 'Tribute Select (HMO-POS I-SNP)',
        description: 'Tribute Select (HMO-POS I-SNP) is a Medicare Advantage health plan designed specifically for Medicare beneficiaries who reside in a long-term care facility. Tribute Select covers Original Medicare benefits plus Medicare Part D prescription drugs. This plan is offered by Arkansas Superior Select, Inc. and uses the Vantos Health Network to serve its members throughout the State of Arkansas.',
    };
    const dsnpTextModel = {
        maPlanId: '',
        maPlanTypeId: 1,
        name: 'Tribute Advantage (HMO-POS D-SNP)',
        description: 'Tribute Advantage (HMO-POS D-SNP) is a Medicare Advantage Dual Special Needs plan designed specifically for Medicare and Medicaid beneficiaries (also called dual-eligible) who reside in a long-term care facility or live at home in their community. This plan is offered by Arkansas Superior Select, Inc. and uses the Vantos Health Network to serve its members throughout the State of Arkansas.',
    }
    const [selectedFacilityAddress, setSelectedFacilityAddress] = useState('');
    const [selectedFacilityPhone, setSelectedFacilityPhone] = useState('');
    const [loadError, setLoadError] = useState(false);
    const [soaForm, setSoaForm] = useState(soaFormModel);
    const [fetchAgent, setFetchAgent] = useState(false);
    const [agentError, setAgentError] = useState(false);
    const [hideUseRole, setHideUserRole] = useState(false);
    const [isAgent, setIsAgent] = useState('');
    const [showAgentInfo, setShowAgentInfo] = useState(false);
    const [isPostError, setIsPostError] = useState(false);
    const [races, setRaces] = useState([]);
    const [ethnicities, setEthnicities] = useState([]);
    const [selectedRaces, setSelectedRaces] = useState([]);
    const [selectedEthnicities, setSelectedEthnicities] = useState([]);
    const [memberEnrollmentExists, setMemberEnrollmentExists] = useState(false);
    const [premiumPaymentOptions, setPremiumPaymentOptions] = useState([]);
    const [applicationFile, setApplicationFile] = useState(null);
    const [salesAgentAssisted, setSalesAgentAssisted] = useState(false);
    const [salesAgentEntered, setSalesAgentEntered] = useState(false);
    const [telephonicApplication, setTelephonicApplication] = useState(false);
    const [enteringSalesAgentId, setEnteringSalesAgentId] = useState('');
    const [assistingAgentName, setAssistingAgentName] = useState('');
    
    const resetSignature = useCallback((name) => {
        setConfirmation(prev => ({...prev, [name]: ''}));
    }, [step]);

    const firstUpdate = useRef(true);

    useEffect(() => {
        function getContractId(maPlanTypeId) {
            return maPlans.find(x => x.maPlanTypeId === maPlanTypeId).contractId;
        }

        function getMaPlanId(maPlanTypeId) {
            return maPlans.find(x => x.maPlanTypeId === maPlanTypeId).id;
        }

        if (planChoice === 'isnp') {
            setTextModel(isnpTextModel);
            setMaPlanId(getMaPlanId(2));
            setContractId(getContractId(2));
        } else if (planChoice === 'dsnp') {
            setTextModel(dsnpTextModel);
            setMaPlanId(getMaPlanId(1));
            setContractId(getContractId(1));
        }

    }, [planChoice])

    useEffect(() => {
        function handlePlanChoiceChange() {
            const isnp = (screen.medicareYesNo === 'yes'
                    && screen.facilityYesNo === 'yes')
                || (screen.medicareYesNo === 'yes'
                    && screen.facilityYesNo === 'no'
                    && screen.homeYesNo === 'yes');

            const dsnp = (screen.medicareYesNo === 'yes'
                && screen.medicaidYesNo === 'yes'
                && screen.facilityYesNo === 'no'
                && screen.homeYesNo === 'no');

            const notQualified = (screen.medicareYesNo === 'yes'
                && screen.medicaidYesNo === 'no'
                && screen.facilityYesNo === 'no'
                && screen.homeYesNo === 'no');

            if (isnp === true) {
                setPlanChoice("isnp");
            } else if (dsnp) {
                setPlanChoice("dsnp");
            } else if (notQualified) {
                setPlanChoice("notQualified");
            } else {
                setPlanChoice("");
            }
        }

        handlePlanChoiceChange();
    }, [screen.facilityYesNo, screen.homeYesNo, screen.medicaidYesNo, screen.medicareYesNo])

    useEffect(() => {
        function handleFacilityAddress(facilityId) {
            const facility = facilities.find(({id}) => id === parseInt(facilityId, 10));
            if (facility) {
                setSelectedFacilityAddress(
                    facility.location.address1 + ' '
                    + facility.location.city + ', '
                    + facility.location.stateOrProvince + ' '
                    + facility.location.zipPostalCode);
            } else {
                setSelectedFacilityAddress('');
            }
        }

        handleFacilityAddress(screen.facilityId);
    }, [screen.facilityId, facilities])

    useEffect(() => {
        function handleSignatureDate() {
            const name = 'signatureDate';
            const value = getTodayDate();
            setConfirmation(prev => ({...prev, [name]: value}));
            if (isAgent === 'yes') {
                setSoaForm(prev => ({...prev, [name]: value}));
            }
        }
        handleSignatureDate();
    }, [step])

    useEffect(() => {
        // Set default userRole to MEMBER
        const name = 'userRole';
        const value = 'MEMBER';
        setConfirmation(prev => ({...prev, [name]:value}))
    }, [])

    const handleScreenChange = ({target}) => {
        const name = target.name;
        const value = target.value;
        const options = target.selectedOptions;
        const facilityId = 'facilityId';
        const facilityName = 'facilityName';
        const homeYesNo = 'homeYesNo';
        if (!options) {
            // yes/no 
            if (name === 'facilityYesNo') {
                if (value === 'no') {
                    // reset
                    setScreen(prev => ({...prev, [facilityName]: ''}));
                    setScreen(prev => ({...prev, [facilityId]: ''}));
                }
            }
            setScreen(prev => ({...prev, [name]: value}));
        } else {
            // facility drop down
            setScreen(prev => ({...prev, [name]: options[0].text}));
            setScreen(prev => ({...prev, [facilityId]: value}));
        }
    }

    const handleMemberChange = ({target}) => {
        let path;
        const name = target.name;
        const value = target.value;
        if (name.indexOf('.') > 0) {
            path = name.split('.');
            switch (path[0]) {
                case 'location':
                    handleMemberAddressChange(path[1], value);
                    break;
                case 'location2':
                    handleMailingAddressChange(path[1], value);
                    break;
                default:
                    break;
            }
        } else {
            setMember({...member, [name]: value});
        }
    }

    const handleMemberAddressChange = (name, value) => {
        setMemberAddress(prev => ({...prev, [name]: value}));
    }

    const handleMailingAddressChange = (name, value) => {
        setMailingAddress(prev => {
            return {...prev, [name]: value}
        });
    }

    const handleCoverageChange = ({target}) => {
        const name = target.name;
        const value = name === 'medicareNumber' ? target.rawValue : target.value;

        if (name === 'additionalDrugCoverageValue' && value === 'no') {
            // Set the value and reset drug coverage fields
            setMedicareCoverage(prev => ({
                medicareNumber: prev.medicareNumber,
                additionalDrugCoverageValue: value,
                additionalDrugCoverageName: '',
                additionalDrugCoverageMemberNumber: '',
                additionalDrugCoverageGroupNumber: '',
                dsnpEnrollmentValue: prev.dsnpEnrollmentValue,
                dsnpEnrollmentMedicaidNumber: prev.dsnpEnrollmentMedicaidNumber,
                primaryCarePhysician: prev.primaryCarePhysician
            }));
            return;
        }

        if (name === 'dsnpEnrollmentValue' && value === 'no') {
            // Set the value and reset Medicaid Number field
            setMedicareCoverage(prev => ({
                medicareNumber: prev.medicareNumber,
                additionalDrugCoverageValue: prev.additionalDrugCoverageValue,
                additionalDrugCoverageName: prev.additionalDrugCoverageName,
                additionalDrugCoverageMemberNumber: prev.additionalDrugCoverageMemberNumber,
                additionalDrugCoverageGroupNumber: prev.additionalDrugCoverageGroupNumber,
                dsnpEnrollmentValue: value,
                dsnpEnrollmentMedicaidNumber: '',
                primaryCarePhysician: prev.primaryCarePhysician
            }));
            return;
        }

        setMedicareCoverage(prev => ({...prev, [name]: value}));
    }

    const handlePremiumChange = ({target}) => {
        const name = target.name;
        const value = target.value;
        setPremium(prev => ({...prev, [name]: value}));
    }
    
    function resetPremium() {
        const name = "premiumPaymentOptionId";
        setPremium(prev => ({...prev, [name]: ""}));
    }

    const handleOptionalChange = ({target}) => {
        const checked = target.checked;
        const name = target.name;
        const value = target.value;
        const type = target.type;
        (checked || type !== 'checkbox')
            ? setOptional({...optional, [name]: value})
            : setOptional({...optional, [name]: ''});
    }

    const handleEthnicityChange = ({target}) => {
        const checked = target.checked;
        const value = parseInt(target.value, 10);
        (checked)
            ? setSelectedEthnicities(prev => ([...prev, value ]))
            : setSelectedEthnicities(prev => ([...prev, '']));
    }

    const handleRaceChange = ({target}) => {
        const checked = target.checked;
        const value = parseInt(target.value, 10);
        (checked)
            ? setSelectedRaces(prev => ([...prev, value ]))
            : setSelectedRaces(prev => ([...prev, '']));
    }
    
    const handleConfirmationChange = ({target}) => {
        const id = target.id;
        const name = target.name;
        const value = target.value;
        const checked = target.checked;
        const type = target.type;
        if (checked || type !== 'checkbox') {
            setConfirmation({...confirmation, [name]: value});
        } else {
            setConfirmation({...confirmation, [name]: ''});
        }

        if (name === "userRole") {
            console.log('handleConfirmationChange name is userRole', checked, id);
            if (checked === true && id !== 'member') {
                setIsAuthRep(true);
            } else {
                setIsAuthRep(false);
                if (confirmation.userRole === 'REP') {
                    resetAuthorizedRepresentative();
                }

            }
        }
    }

    const handleAuthorizedRepChange = ({target}) => {
        const name = target.name;
        const value = target.value;
        setAuthorizedRepresentative({...authorizedRepresentative, [name]: value})

        // handleUserRole();
    }
    
    const handleSoaBeneficiaryChange = ({target}) => {
        const name = target.name;
        const value = target.value;
        setMember(prev => ({...prev, [name]: value}));

        handleUserRole();
        handleHideUserRole();
    }

    const handleUserRole = () => {
        if (authorizedRepresentative.firstName === '' ||
            authorizedRepresentative.lastName === '' ) {
            setUserRole('MEMBER');
        } else {
            setUserRole('REP');
        }
    }

    const handleHideUserRole = () => {
        if (authorizedRepresentative.firstName === '' ||
            authorizedRepresentative.lastName === '' ) {
            setHideUserRole(true);
        } else {
            setHideUserRole(false);
        }
    }

    const handleIsAgent = (value) => {
        setIsAgent(value);
    }

    const handleIsPostError = (value) => {
        setIsPostError(value);
    }
    function handleMemberEnrollmentExists(model) {
        setMemberEnrollmentExists(model);
    }
    function resetAuthorizedRepresentative() {
        setAuthorizedRepresentative({
            lastName: "",
            firstName: "",
            middleInitial: "",
            address1: "",
            city: "",
            stateOrProvince: "",
            zipPostalCode: "",
            phoneNumber: "",
            relationshipToMember: ""
        });
    }

    async function postApplication(model) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(model)
        }

        let response = await fetch("api/TributeOnline/PostApplication/", requestOptions);
        if (!response.ok) {
            handleIsPostError(true);
            throw Error(response.statusText);
        }
        //return await response.json();
    }

    async function handlePost(base64String, urlString) {
        const model = mapPostModel(base64String);
        console.log('handlePost', model);
        if (model.IsSalesAgentEntered && model.ApplicationFile !== null 
            && model.ApplicationFile !== "") {
            model.ApplicationFile = await blobToBase64(applicationFile);
        } 
        
        postApplication(model)
            .then(() => {
                console.log('form submitted');
            })
            .catch(error => {
                handleIsPostError(true);
                console.warn('handleFormSubmit error', error.message);
            })
            .finally(() => {
                setTimeout(() => {
                    handleStepChange(step + 1);
                    setLoading(false);
                    setIsSubmitting(false);
                }, 1000)
            });
    }

    async function handleFormSubmit() {
        setIsSubmitting(true);
        printPDF(handlePost)
            .then(() => {
                console.log('PDF generated');
            });
    }

    async function fetchMemberEnrollmentExists(model) {
        const uri = "api/TributeOnline/MemberEnrollmentExists?Mbi=" + model.Mbi + "&MaPlanId=" + model.MaPlanId; 
        let response = await fetch(uri);
        if (!response.ok) {
            handleIsPostError(true);
            throw Error(response.statusText);
        }
        return await response.json();
    }

    function handleLoadingChange(isLoading) {
        setLoading(isLoading);
    }

    function handleIsSubmitting(value) {
        setIsSubmitting(value);
    }

    const handleSOAFormChange = ({target}) => {
        if (target.name === 'isAgent') {
            handleIsAgent(target.value);
            return;
        }
        setSoaForm(prev => ({...prev, [target.name]: target.value}));
    }

    function resetAgent() {
        resetAgentItem('agentId');
        resetAgentItem('agentName');
        resetAgentItem('agentPhone');
        resetAgentItem('agentEmail');
        setAgentError(false);
    }

    function resetAgentItem(name) {
        setSoaForm(prev => ({...prev, [name]: ''}));
    }

    function resetScreenItem(name) {
        setScreen(prev => ({...prev, [name]: ''}));
    }

    function setScreenItem(name, value) {
        setScreen(prev => ({...prev, [name]: value}));
    }

    function setIsAuthRepresentative(value) {
        setIsAuthRep(value);
    }

    function setUserRole(value){
        const name = 'userRole';
        setConfirmation(prev => ({...prev, [name]:value}));
    }

    function handleSetSelectedRaces(e){
        const value = e.value;
        setSelectedRaces(value);
    }

    function handleSetSelectedEthnicities(e){
        const value = e.value;
        setSelectedEthnicities(value);
    }
    
    function getCheckboxIdList(formName) {
        let ids = [];
        const checkboxes = document.getElementById(formName).querySelectorAll('input[type=checkbox]:checked');
        checkboxes.forEach(value => ids.push(value.id));
        return ids;
    }

    function getCheckboxValueList(formName) {
        let values = [];
        const checkboxes = document
            .getElementById(formName)
            .querySelectorAll('input[type=checkbox]:checked');
        checkboxes.forEach(value => values.push(value.name));
        return values;
    }

    const state = {
        'step': step,
        'lastStep': lastStep,
        'loading': loading,
        'isAuthRep': isAuthRep,
        'maPlanId': maPlanId,
        'maPlans': maPlans,
        'facilities': facilities,
        'screen': screen,
        'member': member,
        'medicareCoverage': medicareCoverage,
        'premium': premium,
        'optionalInformation': optional,
        'confirmation': confirmation,
        'authorizedRepresentative': authorizedRepresentative,
        'memberAddress': memberAddress,
        'mailingAddress': mailingAddress,
        'confirmationNumber': confirmationNumber,
        'authorizedRepresentativeRelationships': authorizedRepresentativeRelationships,
        'handleScreenChange': handleScreenChange,
        'handleMemberChange': handleMemberChange,
        'handleCoverageChange': handleCoverageChange,
        'handlePremiumChange': handlePremiumChange,
        'handleOptionalChange': handleOptionalChange,
        'handleConfirmationChange': handleConfirmationChange,
        'handleAuthorizedRepChange': handleAuthorizedRepChange,
        'handleMemberAddressChange': handleMemberAddressChange,
        'handleMailingAddressChange': handleMailingAddressChange,
        'handleLoadingChange': handleLoadingChange,
        'planChoice': planChoice,
        'setPlanChoice': setPlanChoice,
        'textModel': textModel,
        'handlePost': handlePost,
        'isSubmitting': isSubmitting,
        'handleIsSubmitting': handleIsSubmitting,
        'contractId': contractId,
        'selectedFacilityAddress': selectedFacilityAddress,
        'selectedFacilityPhone': '',
        'loadError': loadError,
        'soaForm': soaForm,
        'handleSoaFormChange': handleSOAFormChange,
        'handleSoaBeneficiaryChange': handleSoaBeneficiaryChange,
        'handleFetchAgent': handleFetchAgent,
        'resetAgent': resetAgent,
        'resetAgentItem': resetAgentItem,
        'agentError': agentError,
        'resetScreenItem': resetScreenItem,
        'resetSignature': resetSignature,
        'setScreenItem': setScreenItem,
        'setIsAuthRepresentative': setIsAuthRepresentative,
        'setUserRole': setUserRole,
        'hideUseRole': hideUseRole,
        'handleIsAgent': handleIsAgent,
        'isAgent': isAgent,
        'showAgentInfo': showAgentInfo,
        'isPostError': isPostError,
        'races': races,
        'ethnicities': ethnicities,
        'selectedRaces': selectedRaces,
        'selectedEthnicities': selectedEthnicities,
        'handleSetSelectedRaces': handleSetSelectedRaces,
        'handleSetSelectedEthnicities': handleSetSelectedEthnicities,
        'handleEthnicityChange': handleEthnicityChange,
        'handleRaceChange':handleRaceChange,
        'resetPremium': resetPremium,
        'fetchMemberEnrollmentExists': fetchMemberEnrollmentExists,
        'handleMemberEnrollmentExists': handleMemberEnrollmentExists,
        'memberEnrollmentExists': memberEnrollmentExists,
        'premiumPaymentOptionId': 1,
        'premiumPaymentOptions': premiumPaymentOptions,
        'salesAgentAssisted': salesAgentAssisted,
        'salesAgentEntered': salesAgentEntered,
        'telephonicApplication': telephonicApplication,
        'applicationFile': applicationFile,
        'enteringSalesAgentId': enteringSalesAgentId,
        'assistingAgentName': assistingAgentName,
        'handleSalesAgentEnteredChange': (value) => { setSalesAgentEntered(value); setSalesAgentAssisted(value); },
        'handleSalesAgentAssistedChange': (value) => {setSalesAgentAssisted(value)},
        'handleAssistingAgentNameChange': (value) => { setAssistingAgentName(value);},
        'handleTelephonicApplicationChange': (value) => { setTelephonicApplication(value) },
        'handleSalesAgentIdChange': (value) => { setEnteringSalesAgentId(value) },
        'handleApplicationFileChange': (file) => { setApplicationFile(file) },
    }

    async function fetchContractData() {
        await fetch("api/TributeOnline/")
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(data => {
                    setFacilities(data.facilities);
                    setConfirmationNumber(String(data.confirmationNumber).padStart(10, '0'));
                    setAuthorizedRepresentativeRelationships(data.authorizedRepresentativeRelationships);
                    setMaPlans(data.maPlans);
                    setRaces(data.races);
                    setEthnicities(data.ethnicities);
                    setPremiumPaymentOptions(data.premiumPaymentOptions);
                }
            )
            .catch((error) => {
                setLoadError(true);
                console.error('fetchContractData error', error);
            })

    }

    useEffect(() => {
        setLastStep(6);
        fetchContractData().then(() => {
            setLoading(false);
        });

    }, []);

    async function fetchAgentData(npn) {
        const uri = "api/TributeOnline/FindAgentInformation?npn=" + npn;
        await fetch(uri)
            .then(response => {
                if (response.ok) {
                    setShowAgentInfo(true);
                }
                if (!response.ok) {
                    setAgentError(true);
                }
                return response.json();
            })
            .then(data => {
                    let name = 'agentId';
                    setSoaForm(prev => ({...prev, [name]: data.agentId}));
                    name = 'agentName';
                    setSoaForm(prev => ({...prev, [name]: data.agentName}));
                    name = 'agentPhone';
                    setSoaForm(prev => ({...prev, [name]: data.agentPhone}));
                    name = 'agentEmail';
                    setSoaForm(prev => ({...prev, [name]: data.agentEmail}));
                }
            )
            .catch((error) => {
                setLoadError(true);
                console.error('fetchAgentData error', error);
            })

    }
    
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        const npn = soaForm.agentNpn;
        fetchAgentData(npn).then(() => {
            setLoading(false);
        });

    }, [fetchAgent]);

    function handleFetchAgent() {
        setFetchAgent(!fetchAgent);
    }
    
    function handleStepChange(step) {
        setStep(() => {
            return step;
        });
    }

    function mailingAddressIsNotPopulated(mailingAddress) {
        return ((mailingAddress.address1 === null || mailingAddress.address1 === "")
            && (mailingAddress.address2 === null || mailingAddress.address2 === "")
            && (mailingAddress.address3 === null || mailingAddress.address3 === "")
            && (mailingAddress.city === null || mailingAddress.city === "")
            && (mailingAddress.stateOrProvince === null || mailingAddress.stateOrProvince === "")
            && (mailingAddress.zipPostalCode === null || mailingAddress.zipPostalCode === ""))
    }

    function mapMailingAddressToMemberAddress() {
        mailingAddress.address1 = memberAddress.address1;
        mailingAddress.address2 = memberAddress.address2;
        mailingAddress.address3 = memberAddress.address3;
        mailingAddress.city = memberAddress.city;
        mailingAddress.stateOrProvince = memberAddress.stateOrProvince;
        mailingAddress.zipPostalCode = memberAddress.zipPostalCode;
    }

    function getEffectiveDate() {
        const today = new Date();
        const thisMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0
        const nextMonth = (thisMonth === "12") ? "01" : String(today.getMonth() + 2).padStart(2, '0');
        const yyyy = (thisMonth === "12") ? today.getFullYear() + 1 : today.getFullYear();
        return yyyy + "-" + nextMonth + "-01";
    }

    function getEffectiveDateYear() {
        const today = new Date();
        const day = today.getDate();
        const thisMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0

        if (day < 7) {
            return today.getFullYear();
        } else {
            return (thisMonth === "12") ? today.getFullYear().toString() + 1 : today.getFullYear().toString();
        }

    }

    function getTodayDate() {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0'); //January is 0
        const yyyy = today.getFullYear();
        return yyyy + "-" + month + "-" + day;
    }

    function getTodayTime() {
        const today = new Date();
        const [hours, minutes] = [today.getHours(), today.getMinutes()];
        return hours + ":" + minutes;

    }

    function nullIfBlank(element) {
        return (element === '') ? null : element;
    }

    function mapPostModel(pdf) {
        const d = new Date();
        const year = d.getFullYear();
        const effectiveDate = getEffectiveDate();
        if (mailingAddressIsNotPopulated(mailingAddress)) {
            mapMailingAddressToMemberAddress();
        }
        return (
            {
                FirstName: member.firstName,
                LastName: member.lastName,
                MiddleInitial: member.middleInitial,
                DateOfBirth: member.dateOfBirth,
                Gender: member.gender,
                Address1: memberAddress.address1,
                City: memberAddress.city,
                County: memberAddress.county,
                Zip: memberAddress.zipPostalCode,
                State: memberAddress.stateOrProvince,
                Phone: removeDashes(member.phone),
                Email: nullIfBlank(optional.enrolleeEmailAddress),
                PermissionToEmail: (optional.enrolleeEmailAddress !== ''),
                MailingAddress1: mailingAddress.address1,
                MailingCity: mailingAddress.city,
                MailingState: mailingAddress.stateOrProvince,
                MailingZip: mailingAddress.zipPostalCode,
                OtherRxCoverage: medicareCoverage.additionalDrugCoverageValue === 'yes',
                OtherRxCoverageName: nullIfBlank(medicareCoverage.additionalDrugCoverageName),
                OtherRxCoverageGroup: nullIfBlank(medicareCoverage.additionalDrugCoverageGroupNumber),
                OtherRxCoverageId: nullIfBlank(medicareCoverage.additionalDrugCoverageMemberNumber),
                LongTerm: screen.facilityYesNo === 'yes',
                LongTermName: screen.facilityName === '' ? null : screen.facilityName,
                LongTermAddress: selectedFacilityAddress,
                LongTermPhone: removeDashes(selectedFacilityPhone),
                AuthorizedRepName: nullIfBlank(authorizedRepresentative.firstName + ' ' + authorizedRepresentative.lastName),
                AuthorizedAddress: nullIfBlank(authorizedRepresentative.address1),
                AuthorizedCity: nullIfBlank(authorizedRepresentative.city),
                AuthorizedState: nullIfBlank(authorizedRepresentative.stateOrProvince),
                AuthorizedZip: nullIfBlank(authorizedRepresentative.zipPostalCode),
                AuthorizedPhone: nullIfBlank(removeDashes(authorizedRepresentative.phoneNumber)),
                AuthorizedRepRelationship: nullIfBlank(authorizedRepresentative.relationshipToMember),
                StateMedicaid: (medicareCoverage.dsnpEnrollmentMedicaidNumber !== ''),
                MedicaidNumber: nullIfBlank(removeDashes(medicareCoverage.dsnpEnrollmentMedicaidNumber)),
                PrimaryCarePhysician: medicareCoverage.primaryCarePhysician === '' ? 'Unknown' : medicareCoverage.primaryCarePhysician,
                SubmitTime: getTodayTime(),
                EnrollmentPlanYear: getEffectiveDateYear(),
                PlanId: maPlanId,
                ContractId: contractId,
                EffectiveDate: effectiveDate,
                InformationInAlternateFormat: (optional.accessibleFormat !== ''),
                MemberBeneficiaryId: medicareCoverage.medicareNumber,
                ApplicationSignatureDate: confirmation.signatureDate,
                ApplicationReceiptDate: confirmation.receiptDate,
                RequestsBrailleMaterial: optional.accessibleFormat === 'braille',
                RequestsLargePrintMaterial: optional.accessibleFormat === 'largePrint',
                RequestsAudioMaterial: optional.accessibleFormat === 'audioCd',
                ConfirmationNumber: String(confirmationNumber).padStart(10, '0'),
                DateOfScope: null,
                AttachmentData: pdf,
                SelectedEthnicityIds: selectedEthnicities,
                SelectedRaceIds: selectedRaces,
                LocationId: nullIfBlank(screen.facilityId),
                PremiumPaymentOptionId: premium.premiumPaymentOptionId,
                
                AgentId: soaForm.agentNpn,
                SalesAgentAssisted: salesAgentAssisted,
                IsSalesAgentEntered: salesAgentEntered,
                IsTelephonicApplication: telephonicApplication,
                ApplicationFile: applicationFile,
                EnteringSalesAgentId: enteringSalesAgentId,
                AgentInfoFromWeb: assistingAgentName
            }
        )
    }

    return (
        loading
            ? <SpinnerBasic/>
            : <Home {...state}
                    onChange={handleStepChange}
                    onFormSubmit={handleFormSubmit}
                    onLoading={handleLoadingChange}
                    member={member}
                    title={title}/>
    );
}
