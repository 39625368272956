import React from 'react';

export function SpinnerBasic(props) {
    const style = {
        position: "fixed", 
        backgroundColor: "#f1f1f1", 
        zIndex: "9999",
        width: "100%",
        height: "100vh",
        paddingTop: "30vh"}

    return(
        <div style={style} data-html2canvas-ignore="true">
            
            <div className="row">
                <div className="col text-center">
                    <div className="spinner-border" role="status">&nbsp;</div>
                </div>
            </div>

            <div className="row">
                <div className="col text-center mt-5">
                    Working ...
                </div>
            </div>
        </div>
    )
}

