import React from 'react';
import '../custom.css';

export function NavFooter(props) {

    const step = props.step;
    const lastStep = props.lastStep;
    const showFooter = props.display;

    const setCurrentStep = (step) => {
        props.onChange(step);
    }
    
    const onSubmit = (e) => {
        if (validate(e)) {
            props.onFormSubmit(e);
        }
    }

    const prev = () => {
        setCurrentStep(
            (step <= 1)
                ? 1
                : step - 1);
    }

    const next = (e) => {
        if (validate(e)) {
            setCurrentStep(
                (step === lastStep)
                    ? lastStep
                    : step + 1);
        }
    }

    const previousButton = () => {
        return (
            <button id="previousBtn" 
                    className="btn btn-link m-3 no-print" 
                    type="button" 
                    onClick={prev}
                    hidden={step <= 1 || step === lastStep} 
                    data-html2canvas-ignore="true">&lt;&nbsp;Previous
            </button>
        )
    }

    const nextButton = () => {
        let btn = <></>;
        const confirm = lastStep - 1;
        if (step < lastStep) {
            btn = <input className="btn btn-danger m-3 no-print" style={{width: '160px'}} 
                         type="button" value="Next >" data-html2canvas-ignore="true" onClick={next} 
                         disabled={props.disabled}/>
        }
        if (step === confirm) {
            btn = <input className="btn btn-success m-3" style={{width: '160px'}} 
                         type="button" value="Submit" onClick={onSubmit}
                         disabled={props.disabled} data-html2canvas-ignore="true"/>
        }
        if (step === lastStep) {
            btn = <></>
        }
        return (btn);
    }

    const validate = function(e){
        const form = e.target.form;
        const valid = form.checkValidity();
        if (!valid) {
            form.reportValidity();
            return false;
        }
        return true;
    }

    return ( 
        showFooter &&
        <div id={"navFooter"} className="row mt-5 no-print">
            <div className='col'>
                {previousButton()}
            </div>
            <div className='col text-right'>
                {nextButton()}
            </div>
        </div>
    )
}