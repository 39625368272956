import React, {useEffect, useRef, useState} from "react";
import {YesNo} from "./YesNo";
import hospital from "../assets/hospital.png";
import stethoscope from "../assets/stethoscope.png";
import firstAid from "../assets/first-aid.png";
import {ReactComponent as ExclamationTriangle} from "../assets/exclamation-triangle.svg"
import {
    Collapse,
    PopoverBody,
    UncontrolledPopover,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import {NavFooter} from "./NavFooter";
import {RequiredStar} from "./RequiredStar";
import {pageModel as page} from "../models/models";

export function Screen(props) {
    const thisStep = 0;
    const [showPage, setShowPage] = useState(false);
    const display = props.step === thisStep && !props.loadError ? '' : 'hidden';
    const [showModal, setShowModal] = useState(false);
    const [showCard, setShowCard] = useState({
        medicareYesNo: true,
        medicaidYesNo: true,
        facilityYesNo: true,
        homeYesNo: true
    });
    const firstUpdate = useRef(true);

    useEffect(() => {
        // Skip, not required if not using an Agent.
        const soaQualified = props.isAgent === 'yes';
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (soaQualified && props.step === page.screen) {
            props.onChange(props.step + 1);
        }
        if (!soaQualified) {
            setShowPage(true);
        }
    }, [props.step])
    

    useEffect(() => {
        setShowModal(props.screen.medicareYesNo === 'yes' &&
            props.screen.medicaidYesNo === 'no' &&
            props.screen.facilityYesNo === 'no' &&
            props.screen.homeYesNo === 'no'
        );
    }, [props.screen])

    const handleYesNo = function ({target}) {
        const name = target.name;
        const value = target.value;

        // Show/hide questions based on Medicare answer
        if (name === 'medicareYesNo') {
            setShowCard({
                medicareYesNo: value === 'no',
                medicaidYesNo: value !== 'no',
                facilityYesNo: value !== 'no',
                homeYesNo: value !== 'no'
            });
        }
    }

    const toggleModal = function () {
        setShowModal(prev => (!prev));
    }

    return (
        <div className={`container ${display} print`} id="screen" >
            <form id="formScreen" name="formScreen">
                <div className="row mt-4">
                    <div className={"col-sm-8 offset-sm-2"} role={"article"}>
                        <h1 className="brand-color-lightgray">Let’s Get Started</h1>
                        <p>To find the Medicare Advantage plan that best meets your needs, please answer the following
                            questions.
                        </p>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className={"col-sm-8 offset-sm-2"}>
                        <div className={"row"}>
                            <div className={"col-sm-10 offset-sm-1"}>
                                <div className={"card mt-3"}>
                                    <div className={"card-header"}>
                                        <div className={"row"}>
                                            <div className={"col"} role="columnheader">Medicare</div>
                                            <div className={"col-xs-2"}>
                                                <button type={"button"} id={"medicareTooltip"}
                                                        className={"tooltip-button no-print"} data-html2canvas-ignore="true">
                                                    <span className={"icon-tooltip"}> </span>
                                                    Medicare Information button
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"card-body"} role={"article"}>
                                        <p className={"card-text pb-3"}>
                                            To join a Tribute Medicare Advantage plan you should have <b>both</b> Medicare Part
                                            A and Part B.
                                        </p>
                                        <div className={"row mt-4"}>
                                            <div className={"col-sm-3"}>
                                                <img src={hospital} alt={"hospital"} />
                                            </div>
                                            <div className={"col-sm-9"}>
                                                Medicare Part A (Hospital Insurance)
                                            </div>
                                        </div>
                                        <div className={"row mt-4"}>
                                            <div className={"col-sm-3"}>
                                                <img src={stethoscope} alt={"medical"} />
                                            </div>
                                            <div className={"col-sm-9"}>
                                                Medicare Part B (Medical Insurance)
                                            </div>
                                        </div>
                                        <div className={"row"}>
                                            <div className={"col-sm-12 text-center"}>
                                                <div className="control-form mt-4 mb-4 screening-question">
                                                    I have both Medicare Part A & Part B.
                                                </div>
                                            </div>
                                            <div className={"col-sm-12 text-center"}>
                                                <YesNo name="medicareYesNo"
                                                       value={props.screen.medicareYesNo}
                                                       onChange={props.handleScreenChange}
                                                       onClick={handleYesNo}
                                                       required={"required"}/>
                                            </div>
                                        </div>
                                        <Collapse isOpen={props.screen.medicareYesNo === 'no'}>
                                            <div className="card card-body text-left mt-3" role={"article"}>
                                                <div className={"row"}>
                                                    <div className={"col-sm-1"}>
                                                        <ExclamationTriangle id="warning-icon"/>
                                                    </div>
                                                    <div className={"col-sm-11"}>
                                                        <p className={"card-text pl-3"}>
                                                            You must have both <b>Medicare Part A</b> <i>and</i> <b>Part
                                                            B</b> to qualify for a Tribute Medicare Advantage plan.
                                                        </p>

                                                    </div>
                                                </div>

                                                <p className={"card-text mt-3"}>
                                                    If you have questions about your Medicare coverage, please
                                                    call <b>800-633-4227</b> or visit <a href={"https://www.medicare.gov/"}
                                                                                         target={"_blank"}
                                                                                         rel="noopener noreferrer">medicare.gov</a>.
                                                </p>
                                            </div>
                                        </Collapse>
                                    </div>
                                </div>
                                <UncontrolledPopover placement={"right"} target={"medicareTooltip"} trigger={"hover"}>
                                    <PopoverBody>
                                        <p className={"card-text"}>
                                            If you have questions about your Medicare coverage, call Medicare
                                            at <b>800-633-4227</b>.
                                        </p>
                                    </PopoverBody>
                                </UncontrolledPopover>

                                <Collapse isOpen={showCard.medicaidYesNo}>
                                    <div className={"card mt-5"}>
                                        <div className={"card-header"} role={"article"}>
                                            <div className={"row"}>
                                                <div className={"col"}>Medicaid</div>
                                            </div>
                                        </div>
                                        <div className={"card-body"} role={"article"}>
                                            <div className={"row"}>
                                                <div className={"col-3"}>
                                                    <img src={firstAid} alt={"Medicaid"} className={"pb-4"}/>
                                                </div>
                                                <div className={"col-9 my-auto pl-0 screening-question"}>
                                                    I currently have Medicaid.
                                                </div>
                                            </div>
                                            <div className={"row"}>
                                                <div className={"col-sm-12 text-center"}>
                                                    <YesNo name="medicaidYesNo"
                                                           value={props.screen.medicaidYesNo}
                                                           onChange={props.handleScreenChange}
                                                           onClick={handleYesNo}
                                                           required={"required"}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Collapse>

                                <Collapse isOpen={showCard.facilityYesNo}>
                                    <div className={"card mt-5"}>
                                        <div className={"card-header"} role={"article"}>
                                            <div className={"row"}>
                                                <div className={"col"}>Facility</div>
                                            </div>
                                        </div>
                                        <div className={"card-body"} role={"article"}>
                                            <div className={"row"}>
                                                <div className={"col-3 mt-3"}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                                         fill="currentColor" data-html2canvas-ignore="true"
                                                         className="bi bi-building mr-4 mb-3 icon-color" viewBox="0 0 16 16">
                                                        <path
                                                            d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
                                                        <path
                                                            d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
                                                    </svg>
                                                </div>
                                                <div className={"col-9 my-auto pl-0 screening-question"}>
                                                    I live in a long-term care facility.
                                                </div>
                                            </div>
                                            <div className={"row"}>
                                                <div className={"col-sm-12 text-center"}>
                                                    <YesNo name="facilityYesNo"
                                                           value={props.screen.facilityYesNo}
                                                           onClick={handleYesNo}
                                                           onChange={props.handleScreenChange}
                                                           required={"required"}/>
                                                </div>
                                            </div>
                                            <div className={"row mt-4"}>
                                                <div className={"col-sm-12 text-center text-strong mt-2"}>
                                                    {props.screen.facilityYesNo === 'yes' && <RequiredStar/>}
                                                    Name of Nursing/Residential Care/Assisted Living
                                                </div>
                                            </div>
                                            <div className={"row"}>
                                                <div className={"col-sm-10 offset-sm-1 text-strong"}>
                                                    <select id="facilityName"
                                                            name="facilityName"
                                                            className="form-control"
                                                            aria-label="Facility name"
                                                            value={props.screen.facilityId}
                                                            onChange={props.handleScreenChange}
                                                            disabled={props.screen.facilityYesNo !== 'yes'}
                                                            required={props.screen.facilityYesNo === 'yes'}>
                                                        <option key="0" value="">&nbsp;</option>
                                                        {
                                                            props.facilities.map(x => <option key={x.id}
                                                                                              value={x.id}>{x.name}</option>)
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Collapse>

                                <Collapse isOpen={showCard.homeYesNo}>
                                    <div className={"card mt-5"}>
                                        <div className={"card-header"} role={"article"}>
                                            <div className={"row"}>
                                                <div className={"col"}>Home Care</div>
                                            </div>
                                        </div>
                                        <div className={"card-body"} role={"article"}>
                                            <div className={"row"}>
                                                <div className={"col-sm-2"}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                                         fill="currentColor" data-html2canvas-ignore="true"
                                                         className="bi bi-house mr-4 mb-3 icon-color" viewBox="0 0 16 16">
                                                        <path
                                                            d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
                                                        <path
                                                            d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
                                                    </svg>
                                                </div>
                                                <div className={"col-sm-8 text-center pl-0 screening-question"}>
                                                    I live at home and require a nursing home level of care.
                                                </div>
                                            </div>
                                            <div className={"row"}>
                                                <div className={"col-sm-12 text-center"}>
                                                    <YesNo name="homeYesNo"
                                                           value={props.screen.homeYesNo}
                                                           onClick={handleYesNo}
                                                           onChange={props.handleScreenChange}
                                                           required={(props.screen.facilityYesNo === 'no') ? 'required' : ''}
                                                           disabled={props.screen.facilityYesNo === 'yes' || props.screen.facilityYesNo === ''}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                </div>

                {props.screen.medicareYesNo === 'yes' &&
                    !(props.screen.medicaidYesNo === 'no' &&
                        props.screen.facilityYesNo === 'no' &&
                        props.screen.homeYesNo === 'no') &&
                    <NavFooter step={props.step}
                               lastStep={props.lastStep}
                               display={props.step === 0}
                               onChange={props.onChange}
                               hasMedicare={props.screen.medicareYesNo}/>
                }
                <Modal toggle={toggleModal} isOpen={showModal}>
                    <ModalHeader toggle={toggleModal}>
                        No Qualifying Plans
                    </ModalHeader>
                    <ModalBody>
                        <p>Based on the information you have provided, you do not qualify for a Tribute Medicare
                            Advantage plan.</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={toggleModal}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </form>
        </div>
    )
}