import React from "react";
import '../custom.css';

export function Footer(props) {

    return (
        <footer className={"mt-4 no-print"}>
            <div className={"row footer-links"}>
                <div className={"col-sm-12 text-center"}>
                    <a tabIndex={0}
                       href={"https://superiorselectinc.com/privacy-and-security/"}
                       target="_blank" rel="noopener noreferrer"
                       title="Tribute Health Plans Privacy Policy">Privacy</a>
                    <span className={"footer-divider"}>|</span>
                    <a tabIndex={0}
                       href={"https://superiorselectinc.com/fraud-waste-and-abuse/"}
                       target="_blank" rel="noopener noreferrer"
                       title="Superior Select Health Plans Fraud, Waste and Abuse Information">Fraud, Waste and
                        Abuse</a>
                    <span className={"footer-divider"}>|</span>
                    <a href={"https://superiorselectinc.com/documents/2019/10/anti-discrimination-2020.pdf/"}
                       target="_blank"
                       title="Tribute Health Plans Anti-Discrimination Notice" rel="noopener noreferrer">
                        Anti-Discrimination Notice</a>
                </div>
            </div>
            <div id="pe-footer">
                <div className={"row"}>
                    <div className={"col-sm-12 text-center pl-5 pt-4"}>
                        <b>Tribute Health Plans</b><br/>
                        PO Box 3630<br/>
                        Little Rock, AR 72202<br/>
                        Toll-free: 1-877-372-1033<br/>
                        (TTY 711)<br/>
                    </div>
                </div>
            </div>
        </footer>
    );
}
