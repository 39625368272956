import React, {useEffect, useState} from 'react';

export function TextInput({label, ...props}) {
    const [requiredStar, setRequiredStar] = useState(null);
    function handleChange() {
        return false;
    }

    useEffect(() => {
        setRequiredStar(
            props.required === 'required'
                ? <span className="required no-print" data-html2canvas-ignore="true">*</span>
                : null)
    }, [props.required])

    return (
        <>
            <label htmlFor={props.id}>{requiredStar}{label}</label>
            <input id={props.id}
                   name={props.name}
                   value={props.value || ''}
                   type={props.type || 'text'}
                   className="form-control"
                   onChange={props.onChange || handleChange}
                   onBlur={props.onBlur}
                   required={props.required}
                   disabled={props.disabled}
                   readOnly={props.readOnly}
                   minLength={props.minLength}
                   maxLength={props.maxLength}
                   aria-label={label}/>
            <small>{props.signatureDisplay}</small>
        </>
    );
}



