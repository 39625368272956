import React, {useEffect, useRef, useState} from "react";
import {NavFooter} from "./NavFooter";
import {Collapse, Row, Col, FormGroup, Input, Label} from "reactstrap";
import {RequiredStar} from "./RequiredStar";
import {getTodayDate} from '../utilities/utilities';
import {StateOrProvince} from "./StateOrProvince";
import {FormID} from "../FormID";
import {Application} from "./Application";

export function Confirmation(props) {
    const display = props.step === 5 ? '' : 'hidden';
    const [signatureDisplay, setSignatureDisplay] = useState('');
    const refSignature = useRef(null);
    const refSignatureConfirm = useRef(null);
    const [agentApplicationType, setAgentApplicationType] = useState('');
    const [whoIsEnteringApplication, setWhoIsEnteringApplication] = useState('');
    const [salesAgentAssisted, setSalesAgentAssisted] = useState(false);

    useEffect(() => {
        // Sets the signature display name
        if (props.authorizedRepresentative.firstName === '' ||
            props.authorizedRepresentative.lastName === '') {
            setSignatureDisplay(props.member.firstName + ' ' + props.member.lastName);
        } else {
            setSignatureDisplay(props.authorizedRepresentative.firstName + ' ' + props.authorizedRepresentative.lastName);
        }
    }, [props.confirmation.userRole,
        props.authorizedRepresentative.firstName,
        props.authorizedRepresentative.lastName,
        props.member.firstName,
        props.member.lastName])

    // set this once here.  It's only overridden if the application is a paper app received by the agent
    useEffect( () => {
        props.confirmation.receiptDate = getTodayDate();
        props.confirmation.signatureDate = getTodayDate();
    }, []);
    function validateZipOrPostalCode(e) {
        const value = e.target.value;
        const el = document.getElementById(e.target.id);
        if (/^\d*$/.test(value)) {
            el.setCustomValidity('');
            props.handleAuthorizedRepChange(e);
        } else {
            el.setCustomValidity('The Zip Code must contain only numbers.');
        }
    }

    const validateForm = function (e) {
        const form = e.target.form;
        const valid = form.checkValidity();
        if (!valid) {
            form.reportValidity();
            return false;
        }
    }
    
    const confirmationValidForSubmit = () => {
        return !!props.confirmation.acknowledgment;
    }

    const handleConfirmationChange = (e) => {
        validateForm(e);
        props.handleConfirmationChange(e);
    }
    
    const handleAgentApplicationTypeChange = (e) => {
        setAgentApplicationType(e.target.value);
        
        if (e.target.value === 'telephonicApplication') {
            props.handleTelephonicApplicationChange(true);
            props.handleApplicationFileChange(null);
        }
    }
    
    const requireSalesAgentName = (e) => {
        console.log('requireSalesAgentName', whoIsEnteringApplication === 'applicant' && props.salesAgentAssisted)
        console.log('requireSalesAgentName', whoIsEnteringApplication === 'applicant' && props.salesAgentAssisted)
        return whoIsEnteringApplication === 'applicant' && props.salesAgentAssisted;
    }
    
    const handleWhoIsEnteringApplicationChange = (e) => {
         setWhoIsEnteringApplication(e.target.value);
         
         if (e.target.value === 'agent') {
             props.handleAssistingAgentNameChange('');
             props.handleSalesAgentEnteredChange(true);
             setSalesAgentAssisted(true);
         }
         
         if (e.target.value === 'applicant') {
             props.handleApplicationFileChange(null);
             props.handleSalesAgentEnteredChange(false);
             props.handleSalesAgentIdChange('');
             setSalesAgentAssisted(false);
         }
    }
    
    const handleSalesAgentIdChange = (e) => {
        props.handleSalesAgentIdChange(e.target.value);
    }
    
    const handleApplicationFileChange = (e) => {
        props.handleApplicationFileChange(e.target.files[0]);
    }
    
    const handleAssistingAgentNameChange = (e) => {
        console.log('handleAssistingAgentNameChange', e.target.checked)
        props.handleAssistingAgentNameChange(e.target.value);
    }

    const handleAgentIsAssistingChange = (e) => {
        setSalesAgentAssisted(e.target.checked);
        props.handleSalesAgentAssistedChange(e.target.checked);
        props.handleAssistingAgentNameChange('');
    }
    const submitForm = (e) => {
        validateForm(e);
        props.onFormSubmit(e);
    }

    const handleSignatureValidation = () => {
        // If user is the member, digital Signature must match member first/last name
        // If user is not the member, digital Signature must match Authorized Representative first/last name
        const memberName = props.member.firstName.trim() + ' ' + props.member.lastName.trim();
        const authRepName = props.authorizedRepresentative.firstName.trim() + ' ' + props.authorizedRepresentative.lastName.trim();
        const relationship = props.confirmation.userRole;
        const signature = refSignature.current.value.trim();
        const signatureConfirm = refSignatureConfirm.current.value.trim();

        // Do the two signature fields match each other?
        if (signature !== signatureConfirm) {
            refSignature.current.setCustomValidity('Signatures must match.');
            return; // fails, no need to do more
        } else {
            refSignature.current.setCustomValidity('');
        }

        switch (relationship.toLowerCase()) {
            case "":
            case "member":
                if (signature !== memberName) {
                    refSignature.current.setCustomValidity('Signature must match the Member name.');
                } else {
                    refSignature.current.setCustomValidity('');
                }
                if (signatureConfirm !== memberName) {
                    refSignatureConfirm.current.setCustomValidity('Signature must match the Member name.');
                } else {
                    refSignatureConfirm.current.setCustomValidity('');
                }
                break;
            case "rep":
            case "poa":
                if (signature !== authRepName) {
                    refSignature.current.setCustomValidity('Signature must match the authorized representative');
                } else {
                    refSignature.current.setCustomValidity('');
                }
                if (signatureConfirm !== authRepName) {
                    refSignatureConfirm.current.setCustomValidity('Signature must match the authorized representative');
                } else {
                    refSignatureConfirm.current.setCustomValidity('');
                }
                break;
        }
    }

    return (
        <div className={`container ${display} print`} id="confirmation" style={{breakInside: 'avoid-page'}}>
            <form method="post" id="formConfirmation" name="formConfirmation">
                <div className="row mt-4">
                    <div className="col">
                        <h1 className="brand-color-lightgray">Signature</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <p><b>IMPORTANT</b>: Read and sign below</p>
                        <hr/>
                    </div>
                </div>
                <div className="row mr-3">
                    <div className="col pt-2">
                        <ul>
                            <li>I must keep both Hospital (Part A) and Medical (Part B) to stay in Tribute.</li>
                            <li>By joining this Medicare Advantage Plan, I acknowledge that Tribute will share my
                                information with Medicare, who may use it to track my enrollment, to make payments, and
                                for other purposes allowed by Federal law that authorize the collection of this
                                information
                                (see Privacy Act Statement below).
                            </li>
                            <li>Your response to this form is voluntary. However, failure to respond may affect
                                enrollment
                                in the plan.
                            </li>
                            <li>The information on this enrollment form is correct to the best of my knowledge. I
                                understand that if I intentionally provide false information on this form, I will be
                                disenrolled
                                from the plan.
                            </li>
                            <li>I understand that people with Medicare are generally not covered under Medicare while
                                out of the country, except for limited coverage near the U.S. border.
                            </li>
                            <li>I understand that when my Tribute coverage begins, I must get all of my medical and
                                prescription drug benefits from Tribute. Benefits and services provided by Tribute and
                                contained in my Tribute “Evidence of Coverage” document (also known as a member
                                contract or subscriber agreement) will be covered. Neither Medicare nor Tribute will pay
                                for benefits or services that are not covered.
                            </li>
                            <li>I understand that my signature (or the signature of the person legally authorized to act
                                on
                                my behalf) on this application means that I have read and understand the contents of
                                this
                                application. If signed by an authorized representative (as described above), this
                                signature
                                certifies that:
                                <ol>
                                    <li>This person is authorized under State law to complete this enrollment, and</li>
                                    <li>Documentation of this authority is available upon request by Medicare.</li>
                                </ol>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row mt-sm-2 mt-4">
                    <div className="col-sm-12">
                        <span className="required no-print" data-html2canvas-ignore="true">*</span>
                        <b>I am the</b>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-sm-12">
                        <div className="form-check">
                            <label className="form-check-label" htmlFor="member">
                                <input type="radio" className="form-check-input"
                                       id="member"
                                       name="userRole"
                                       checked={props.confirmation.userRole === "MEMBER"}
                                       value="MEMBER"
                                       onChange={props.handleConfirmationChange}
                                       required="required"
                                       readOnly={props.isAgent === 'yes'}
                                       disabled={props.isAgent === 'yes'}
                                />
                                Member
                            </label>
                        </div>
                        <div className="form-check">
                            <label className="form-check-label" htmlFor="authorizedRepresentative">
                                <input type="radio" className="form-check-input"
                                       id="authorizedRepresentative"
                                       name="userRole"
                                       checked={props.confirmation.userRole === "REP"}
                                       value="REP"
                                       onChange={props.handleConfirmationChange}
                                       readOnly={props.isAgent === 'yes'}
                                       disabled={props.isAgent === 'yes'}/>
                                Authorized Representative
                            </label>
                        </div>
                    </div>
                </div>

                <Collapse isOpen={props.isAuthRep}>
                    <fieldset id="authRepAddress">
                        <div className="form-row">
                            <div className="col-sm-5">
                                {props.confirmation.userRole !== "MEMBER" && <RequiredStar/>}
                                <label htmlFor="firstNameAuthRep">First Name</label>
                                <input type="text" id="firstNameAuthRep" name="firstName"
                                       value={props.authorizedRepresentative.firstName || ''}
                                       onChange={props.handleAuthorizedRepChange}
                                       onBlur={handleSignatureValidation}
                                       className="form-control"
                                       required={props.confirmation.userRole !== "MEMBER"} 
                                       readOnly={props.isAgent === 'yes'}/>
                            </div>
                            <div className="col-sm-2">
                                <label htmlFor="middleInitialAuthRep">MI</label>
                                <input type="text" id="middleInitialAuthRep" name="middleInitial"
                                       value={props.authorizedRepresentative.middleInitial || ''}
                                       onChange={props.handleAuthorizedRepChange}
                                       onBlur={handleSignatureValidation}
                                       className="form-control" maxLength={1}
                                       readOnly={props.isAgent === 'yes'}/>
                            </div>
                            <div className="col-sm-5">
                                {props.confirmation.userRole !== "MEMBER" && <RequiredStar/>}
                                <label htmlFor="lastNameAuthRep">Last Name</label>
                                <input type="text" id="lastNameAuthRep" name="lastName"
                                       value={props.authorizedRepresentative.lastName || ''}
                                       onChange={props.handleAuthorizedRepChange}
                                       onBlur={handleSignatureValidation}
                                       className="form-control"
                                       required={props.confirmation.userRole !== "MEMBER"}
                                       readOnly={props.isAgent === 'yes'}/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col">
                                {props.confirmation.userRole !== "MEMBER" && <RequiredStar/>}
                                <label htmlFor="address1AuthRep">Address</label>
                                <input type="text" id="address1AuthRep" name="address1"
                                       value={props.authorizedRepresentative.address1 || ''}
                                       onChange={props.handleAuthorizedRepChange}
                                       className="form-control"
                                       required={props.confirmation.userRole !== "MEMBER"}/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-sm-7">
                                {props.confirmation.userRole !== "MEMBER" && <RequiredStar/>}
                                <label htmlFor="cityAuthRep">City</label>
                                <input type="text" id="cityAuthRep" name="city"
                                       value={props.authorizedRepresentative.city || ''}
                                       onChange={props.handleAuthorizedRepChange}
                                       className="form-control"
                                       required={props.confirmation.userRole !== "MEMBER"}/>
                            </div>
                            <div className="col-sm-2">
                                <StateOrProvince name="stateOrProvince"
                                                 id="stateOrProvinceAuthRep"
                                                 value={props.authorizedRepresentative.stateOrProvince || ''}
                                                 onChange={props.handleAuthorizedRepChange}
                                                 required={props.confirmation.userRole !== "MEMBER"}
                                                 />
                            </div>
                            <div className="col-sm-3">
                                {props.confirmation.userRole !== "MEMBER" && <RequiredStar/>}
                                <label htmlFor="zipPostalCodeAuthRep">Zip Code</label>
                                <input type="text" id="zipPostalCodeAuthRep"
                                       value={props.authorizedRepresentative.zipPostalCode || ''}
                                       name="zipPostalCode"
                                       onChange={validateZipOrPostalCode}
                                       className="form-control"
                                       required={props.confirmation.userRole !== "MEMBER"}
                                       minLength={5}
                                       maxLength={5}/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-sm-6">
                                {props.confirmation.userRole !== "MEMBER" && <RequiredStar/>}
                                <label htmlFor="phoneNumberAuthRep">Phone</label>
                                <input type="text" id="phoneNumberAuthRep"
                                       value={props.authorizedRepresentative.phoneNumber || ''}
                                       onChange={props.handleAuthorizedRepChange} name="phoneNumber"
                                       className="form-control"
                                       required={props.confirmation.userRole !== "MEMBER"}/>
                            </div>
                            <div className="col-sm-6">
                                {props.confirmation.userRole !== "MEMBER" && <RequiredStar/>}
                                <label htmlFor="relationshipToMember">Relationship to Member</label>
                                <select id="relationshipToMember"
                                        name="relationshipToMember"
                                        className="form-control"
                                        aria-label="Relationship to Member"
                                        value={props.authorizedRepresentative.relationshipToMember || ''}
                                        onChange={props.handleAuthorizedRepChange}
                                        disabled={props.confirmation.userRole === "MEMBER"}
                                        required={props.confirmation.userRole !== "MEMBER"}>
                                    <option key="0" value="">&nbsp;</option>
                                    {
                                        props.authorizedRepresentativeRelationships.map(
                                            x => <option key={x.id} value={x.code}>{x.description}</option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                    </fieldset>
                </Collapse>
                <div className="row form-row mb-2 mt-2">
                    <div className="form-group col-sm-12 col-lg-4">
                        <label htmlFor={"signature"}>Digital Signature</label>
                        <input id={"signature1"}
                               name={"signature1"}
                               value={props.confirmation.signature1}
                               onChange={props.handleConfirmationChange}
                               onBlur={handleSignatureValidation}
                               required={true}
                               aria-label={"Signature"}
                               type={"text"}
                               className="form-control"
                               ref={refSignature}/>
                        <small>{signatureDisplay}</small>
                    </div>
                    <div className="form-group col-sm-12 col-lg-4">
                        <label htmlFor={"signatureConfirm"}>Confirm Digital Signature</label>
                        <input id={"signature2"}
                               name={"signature2"}
                               value={props.confirmation.signature2}
                               onChange={props.handleConfirmationChange}
                               onBlur={handleSignatureValidation}
                               required={true}
                               aria-label={"Signature Confirmation"}
                               type={"text"}
                               className="form-control"
                               ref={refSignatureConfirm}/>
                        <small>{signatureDisplay}</small>
                    </div>
                    <div className="form-group col-sm-12 col-lg-4">
                        <label htmlFor={"signatureDate"}>Date</label>
                        <input id={"signatureDate"}
                               name={"signatureDate"}
                               value={props.confirmation.signatureDate}
                               aria-label={"SOA Signature Date"}
                               type={"text"}
                               className="form-control"
                               disabled={true}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12" id="relationshipAddress">
                        <div className="row">
                            <div className="col-sm-12">
                                <label htmlFor="acknowledgment">
                                    <span className="required no-print" data-html2canvas-ignore="true">*&nbsp;</span>
                                    <input type="checkbox"
                                           id="acknowledgment"
                                           name="acknowledgment"
                                           value="acknowledgment"
                                           checked={props.confirmation.acknowledgment}
                                           onChange={handleConfirmationChange}
                                           required="required"
                                           disabled={props.confirmation.userRole === ''}/>
                                    &nbsp; By entering my name above I am indicating my
                                    intent to electronically sign this application
                                    and warrant that all the information I have provided is true, complete, and
                                    accurate.
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"row"} id={"whoIsEnteringApplicationSection"}>
                    <div className={"form-group col-sm-12"}>
                        <h4>Who is entering this application?</h4>
                        <label htmlFor={"applicantIsEnteringApplication"}>
                            <input type={"radio"}
                                   id={"applicantIsEnteringApplication"}
                                   name={"whoIsEnteringApplication"}
                                   value={"applicant"}
                                   required={true}
                                   checked={whoIsEnteringApplication === 'applicant'}
                                   onChange={handleWhoIsEnteringApplicationChange}/>
                            &nbsp; I am the applicant entering this application.
                        </label>
                        <br />
                        <label htmlFor={"agentIsEnteringApplication"}>
                            <input type={"radio"}
                                   id={"applicantIsEnteringApplication"}
                                   name={"whoIsEnteringApplication"}
                                   value={"agent"}
                                   required={true}
                                   checked={whoIsEnteringApplication === 'agent'}
                                   onChange={handleWhoIsEnteringApplicationChange} />
                                &nbsp; I am an agent entering this application on behalf of
                                the applicant.
                        </label>
                    </div>
                </div>
                <Row>
                    <Col xs={1}></Col>
                    <Col>
                        <Collapse isOpen={whoIsEnteringApplication === 'applicant'}>
                            <FormGroup check>
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <Input
                                            type={"checkbox"}
                                            id={"salesAgentAssisted"}
                                            name={"salesAgentAssisted"}
                                            value="salesAgentAssisted"
                                            checked={salesAgentAssisted}
                                            onChange={handleAgentIsAssistingChange}
                                        />
                                        {' '}
                                        <Label check>
                                            An agent assisted me with completing this application.
                                        </Label>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <Label
                                            for={"assistingAgentName"}>
                                            <span className="required no-print" data-html2canvas-ignore="true">*</span>
                                            &nbsp; Agent's Name
                                        </Label>
                                        <Input
                                            type={"text"}
                                            id={"assistingAgentName"}
                                            name={"assistingAgentName"}
                                            value={props.assistingAgentName}
                                            required={whoIsEnteringApplication === 'applicant' && props.salesAgentAssisted}
                                            disabled={!salesAgentAssisted}
                                            onChange={handleAssistingAgentNameChange}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Collapse>
                    </Col>
                </Row>
                <Row>
                    <Col xs={1}></Col>
                    <Col>
                        <Collapse isOpen={whoIsEnteringApplication === 'agent'}>
                            <div className={"row"} >
                                <div className={"form-group col-xs-12 col-sm-4"}>
                                    <label htmlFor={"agentId"}><span className="required no-print" data-html2canvas-ignore="true">*</span> Agent NPN</label>
                                    <input type={"text"}
                                           id={"enteringSalesAgentId"}
                                           name={"enteringSalesAgentId"}
                                           className={"form-control"}
                                           value={props.enteringSalesAgentId}
                                           onChange={handleSalesAgentIdChange}
                                           required={props.salesAgentEntered}/>
                                </div>
                                <div className={"form-group col-xs-12 col-sm-6"}>
                                    <label htmlFor={"telephonicApplication"}>
                                        <p><input type={"radio"}
                                                  id={"telephonicApplication"}
                                                  name={"agentApplicationType"}
                                                  value={"telephonicApplication"}
                                                  required={props.salesAgentEntered}
                                                  checked={agentApplicationType === 'telephonicApplication'}
                                                  onChange={handleAgentApplicationTypeChange}/>
                                            &nbsp; I am an agent entering this application on behalf of
                                            the applicant on the telephone.
                                        </p>
                                        <p>
                                            <input type={"radio"}
                                                   id={"paperApplication"}
                                                   name={"agentApplicationType"}
                                                   value={"paperApplication"}
                                                   required={props.salesAgentEntered}
                                                   checked={agentApplicationType === 'paperApplication'}
                                                   onChange={handleAgentApplicationTypeChange}/>
                                            &nbsp; I am an agent entering this application on behalf of
                                            the applicant from a paper application.
                                        </p>
                                    </label>
                                </div>
                            </div>
                            <div className={"row"} hidden={!props.salesAgentEntered || agentApplicationType !== 'paperApplication'}>
                                <div className={"col-sm-12"}>
                                    <label htmlFor={"applicationFile"}>
                                        <span className="required no-print" data-html2canvas-ignore="true">*</span>
                                        <input type={"date"}
                                               id={"overrideReceiptDate"}
                                               name={"overrideReceiptDate"}
                                               required={props.salesAgentEntered && agentApplicationType === 'paperApplication'}
                                               onChange={(e) => {props.confirmation.receiptDate = e.target.value}}/>
                                        &nbsp; Date that the application was received from the applicant.
                                    </label>
                                </div>
                                <div className={"col-sm-12"}>
                                    <label htmlFor={"applicationFile"}>
                                        <span className="required no-print" data-html2canvas-ignore="true">*</span>
                                        <input type={"date"}
                                               id={"overrideSignatureDate"}
                                               name={"overrideSignatureDate"}
                                               required={props.salesAgentEntered && agentApplicationType === 'paperApplication'}
                                               onChange={(e) => {console.log(e.target.value); props.confirmation.signatureDate = e.target.value}}/>
                                        &nbsp; Date that the application was signed by the applicant.
                                    </label>
                                </div>
                            </div>
                            <div className={"row"} hidden={!props.salesAgentEntered || agentApplicationType !== 'paperApplication'}>
                                <div className={"col-sm-12"}>
                                    <label htmlFor={"applicationFile"}>
                                        <span className="required no-print" data-html2canvas-ignore="true">*</span>
                                        <input type={"file"}
                                               id={"applicationFile"}
                                               name={"applicationFile"}
                                               accept={"application/pdf"}
                                               required={props.salesAgentEntered && agentApplicationType === 'paperApplication'}
                                               onChange={handleApplicationFileChange}/>
                                        &nbsp; Upload a copy of the paper application.
                                    </label>
                                </div>
                            </div>
                        </Collapse>
                    </Col>
                </Row>
                <NavFooter step={props.step}
                           lastStep={props.lastStep}
                           display={props.step === 5}
                           onChange={props.onChange}
                           onFormSubmit={submitForm}
                           disabled={!confirmationValidForSubmit()}/>
                <FormID/>
            </form>
        </div>
    );
}