import {jsPDF} from "jspdf";

/* Add this attribute to hide items from printing
* data-html2canvas-ignore="true"
* */

export const blobToBase64 = async blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
        reader.onloadend = () => {
            const base64String = reader.result.split(',')[1];
            resolve(base64String);
        }
    });
}
export const printPDF = async function (callback) {
    const doc = new jsPDF({
        orientation: "p",
        format: "legal",
        unit: "px",
        hotfixes: ["px_scaling"]
    });

    const elementHandler = {
        '#ignorePDF': function () {
            return false;
        }
    }

    // const soa = document.getElementById('soa');
    // const soaRequired = document.getElementById('soaRequired');
    // if (soaRequired && soaRequired.value === 'true') {
    //     if (soa.classList.contains('hidden')) {
    //         soa.classList.remove('hidden');
    //     }
    // }

    const selfDirected = document.getElementById('selfDirected');

    const screen = document.getElementById('screen');
    if (screen.classList.contains('hidden')) {
        screen.classList.remove('hidden');
    }

    const application = document.getElementById('application');
    if (application.classList.contains('hidden')) {
        application.classList.remove('hidden');
    }

    const coverage = document.getElementById('coverage');
    if (coverage.classList.contains('hidden')) {
        coverage.classList.remove('hidden');
    }

    const premium = document.getElementById('premium');
    if (premium.classList.contains('hidden')) {
        premium.classList.remove('hidden');
    }

    const optional = document.getElementById('optional');
    if (optional.classList.contains('hidden')) {
        optional.classList.remove('hidden');
    }

    const confirmation = document.getElementById('confirmation');
    if (confirmation.classList.contains('hidden')) {
        confirmation.classList.remove('hidden');
    }

    const paymentOptions = document.getElementById('paymentOptions');
    if (paymentOptions.classList.contains('hidden')) {
        paymentOptions.classList.remove('hidden');
    }

    const finished = document.getElementById('finished');
    if (finished.classList.contains('hidden')) {
        finished.classList.remove('hidden');
    }

    const body = document.getElementById("content");
    
    let width = 792;
    doc.html(body, {
        autoPaging: 'slice',
        width: width,
        windowWidth: width,
        image: 'jpeg',
        filename: 'Tribute Enrollment Application.pdf',
        html2canvas: {windowWidth: width},
        callback: async function (doc) {
            selfDirected.classList.add('hidden');
            screen.classList.add('hidden');
            application.classList.add('hidden');
            coverage.classList.add('hidden');
            premium.classList.add('hidden');
            optional.classList.add('hidden');
            confirmation.classList.add('hidden');
            paymentOptions.classList.add('hidden');
            finished.classList.remove('hidden');

            // Create the file
            let blob = doc.output('blob');
            let urlString = doc.output('datauristring');
            const base64String = await blobToBase64(blob);
            callback(base64String, urlString);
        },
        elementHandler: elementHandler
    });
}

export default printPDF;
