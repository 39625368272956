import React, {useState} from "react";

export function AproposUnavailable(props) {
    const display = props.step < 1 && props.loadError ? '' : 'hidden';
    const [buttonText] = useState(window.history.length === 1 ? 'Close' : 'Back');

    function goBack() {
        if (buttonText === 'Close') {
            window.close();
        } else {
            window.history.back();
        }
    }

    return (
        <div className={`${display} no-print mt-5`} data-html2canvas-ignore="true">
            <div className={'siteDown'}>
                <div className={"siteDown-item mt-4"}>
                    <p className={"display-4"}>We'll be right back.</p>
                </div>
                <div className={"siteDown-item mt-3"}>
                    <p>Our service is temporarily unavailable. We expect to be back soon.</p>
                </div>
                <div className={"siteDown-item mt-4"}>
                    <h5>Please try again later.</h5>
                </div>
                <div className={"siteDown-item mt-5"}>
                    <button type={"button"} className="btn btn-danger mt-5" onClick={goBack}>{buttonText}</button>
                </div>
            </div>
        </div>
    )
}