import React, {useEffect, useState} from "react";
import {PopoverBody, UncontrolledPopover} from "reactstrap";

export function CustomerSupport(props) {

    // let mediaQueryList1;
    // let mediaQueryList2;
    
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // mediaQueryList1 = window.matchMedia("(min-width: 320px)");
        // mediaQueryList2 = window.matchMedia("(min-width: 570px)");
        // const mediaQueryList3 = window.matchMedia("(min-width: 700px)")
        // const mediaQueryList4 = window.matchMedia("(min-width: 960px)")
        // console.log('min-width 320', mediaQueryList1);
        // console.log('min-width 570', mediaQueryList2.matches);
        // console.log('min-width 700', mediaQueryList3);
        // console.log('min-width 960', mediaQueryList4);
        setMobile();
    }, []);
    
    function setMobile(){
        const nonMobile = window.matchMedia("(min-width: 500px)");
        setIsMobile(!nonMobile.matches);
    }
    
    return (
        <div className={(isMobile ? 'customer-support-mobile' : 'customer-support')  + " no-print"}>
            <button type={"button"} id={"customerSupportTooltip"}
                    data-toggle={"tooltip"} 
                    data-html={"true"} 
                    className={"tooltip-button"}>Custmer Support
                <span className={"customer-support-tooltip"}> </span>
            </button>
            <UncontrolledPopover placement={"left"} 
                                 target={"customerSupportTooltip"} 
                                 trigger={"hover"}>
                <PopoverBody>
                    <div>
                        <div>
                            <h5>Customer Support</h5>
                            <div className={"mt-2"}>
                                To speak to a representative<br/>or to an agent, please call <br/><b>877-372-1033</b>.
                            </div>                             
                        </div>
                    </div>
                </PopoverBody>
            </UncontrolledPopover>
        </div>

    )
}