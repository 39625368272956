import React from "react";
// import Cleave from "cleave.js/react";

export function ErrorOnPost(props) {
    const display = (props.step === 6 && props.isPostError === true ) ? '' : 'hidden';
    
    return <div className={`container ${display} print break-before`} id="finished">
        <form method="post" id="formFinished" name="formFinished">
            <div className="row mt-5">
                <div className="col-sm-8 pr-5">
                    <h1 className="brand-color-red">Our Apologies</h1>
                    <h2 className={"heading-medium brand-color-darkgray"}>An error occurred while processing your application.</h2>
                    <p>Please contact your agent for assistance or call Customer Support at 877-372-1033</p>

                    <h5 className={"heading-medium brand-color-darkgray mt-4"}>Agent</h5>
                    <div>{props.agentInfo.agentName}</div>
                    <div>{props.agentInfo.agentPhone}</div>
                    <div>{props.agentInfo.agentEmail}</div>
                </div>
                <div className="col-sm-4">
                    <div className="row mb-2">
                        <div className={"col-sm-12 offset-sm-1"}>
                            {/*<div className={"card"}>*/}
                            {/*    <div className={"card-body"}>*/}
                            {/*        <p className={"card-text"}>*/}
                            {/*            <b>Confirmation Number</b>*/}
                            {/*            <Cleave options={{*/}
                            {/*                delimiter: '-',*/}
                            {/*                blocks: [3, 4, 3]*/}
                            {/*            }}*/}
                            {/*                    className="form-control"*/}
                            {/*                    id="confirmationNumber"*/}
                            {/*                    name="confirmationNumber"*/}
                            {/*                    value={props.confirmationNumber.toString().padStart(10, '0')}*/}
                            {/*                    constructor={''}*/}
                            {/*                    style={{border: 0, fontWeight: 600}}*/}
                            {/*                    readOnly="readOnly"/>*/}
                            {/*        </p>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        <div className="col-sm-12 mt-5 offset-sm-1">
                            <h5 className="heading-medium brand-color-darkgray">Application</h5>
                            <p>You can print or save a copy of the completed application to send to your agent by selecting an option
                                below.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 mt-3 offset-sm-1">
                            <button className="btn btn-outline-danger w-100" type="button"
                                    onClick={() => window.print()}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-printer" viewBox="0 0 16 16" data-html2canvas-ignore="true">
                                    <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
                                    <path
                                        d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z"/>
                                </svg>
                                &nbsp;&nbsp;Print / Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <div className="row text-center mt-3">
                <div className="col-sm-8 offset-sm-2 mt-4">
                    <h3 className="brand-color-red">For more information:</h3>
                    <div className="row">
                        <div className={"col-sm-12"}>
                            <p>Visit <a href={"https://superiorselectinc.com/"} target={"_blank"} rel="noopener noreferrer">TributeMedicare.com</a> to access
                                our Provider Directory, Pharmacy Directory, and our Prescription Drug Formulary
                                (List of Covered Prescription Drugs).</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className={"col-sm-12"}>
                            <div className="heading-medium brand-color-darkgray">Claims Questions & Appeals Process</div>
                            <p>Member Services (877) 372-1033 (TTY call 711) 8 a.m. - 8 p.m., 7 days a week</p>
                            <p className={"brand-color-darkgray"}><b>1-800-MEDICARE</b> (800 633-4227), 24 hours a day, 7 days a week</p>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
}