import React from "react";
import {NavFooter} from "./NavFooter";
import '../custom.css';
import medicareCard from "../assets/medicare-card-sample-x-sm.jpg";
import {YesNo} from "./YesNo";
import {RequiredStar} from "./RequiredStar";
import Cleave from 'cleave.js/react';
import {FormID} from "../FormID";

export function Coverage(props) {
    const display = props.step === 2 ? '' : 'hidden';
    
    // function medicareNumberIsValid(value){
    //     const nonZero = "([1-9])";
    //     const numeric = "([0-9])";
    //     const alpha = "((?=[^SLOBIZ])[A-Z])";
    //     const alphaNumeric = "((?=[^SLOBIZ])[A-Z0-9])";
    //     const r = new RegExp(`^${nonZero}${alpha}${alphaNumeric}${numeric}${alpha}${alphaNumeric}${numeric}${alpha}${alpha}${numeric}${numeric}`)
    //     return r.test(value);
    // }
    //
    // function handleMedicareNumberChange(e) {
    //     const rawValue = e.target.rawValue;
    //     const input = document.getElementById('medicareNumber');
    //     if (medicareNumberIsValid(rawValue)){
    //         input.setCustomValidity('');
    //     } else {
    //         input.setCustomValidity('Medicare Number is not valid');
    //     }
    //     if (input.reportValidity()) {
    //         props.handleCoverageChange(e);
    //     }
    //     return false;
    // }

    return (
        <div className={`container ${display} print break-before`} id="coverage">
            <form method="post" id="formCoverage" name="formCoverage">
                <div className="row question">
                    <div className="col-sm-12 mt-3">
                        <div>
                            <h4>
                                Drug Coverage
                            </h4>
                            <hr/>
                            <p>
                                Will you have other prescription drug coverage (like VA, TRICARE)
                                in addition to Tribute?
                            </p>
                            <YesNo name="additionalDrugCoverageValue" label="Drug Coverage"
                                   value={props.medicareCoverage.additionalDrugCoverageValue}
                                   onChange={props.handleCoverageChange}
                                   required="required"/>
                        </div>
                    </div>
                </div>
                <div className="row form-row mt-3">
                    <div className="form-group col-sm-12 col-lg-4">
                        {props.medicareCoverage.additionalDrugCoverageValue === 'yes' && <RequiredStar/>}
                        <label htmlFor="additionalDrugCoverageName">Name of other coverage</label>
                        <input type="text" className="form-control"
                               id="additionalDrugCoverageName"
                               name="additionalDrugCoverageName"
                               value={props.medicareCoverage.additionalDrugCoverageValue === 'yes'
                                   ? props.medicareCoverage.additionalDrugCoverageName
                                   : ''}
                               onChange={props.handleCoverageChange}
                               disabled={props.medicareCoverage.additionalDrugCoverageValue !== 'yes'}
                               required={props.medicareCoverage.additionalDrugCoverageValue === 'yes'}/>
                    </div>
                    <div className="form-group col-sm-12 col-lg-4">
                        {props.medicareCoverage.additionalDrugCoverageValue === 'yes' && <RequiredStar/>}
                        <label htmlFor="additionalDrugCoverageMemberNumber">Member number for this coverage</label>
                        <input type="text" className="form-control"
                               id="additionalDrugCoverageMemberNumber"
                               name="additionalDrugCoverageMemberNumber"
                               value={props.medicareCoverage.additionalDrugCoverageValue === 'yes' ? props.medicareCoverage.additionalDrugCoverageMemberNumber : ''}
                               onChange={props.handleCoverageChange}
                               disabled={props.medicareCoverage.additionalDrugCoverageValue !== 'yes'}
                               required={props.medicareCoverage.additionalDrugCoverageValue === 'yes'}/>
                    </div>
                    <div className="form-group col-sm-12 col-lg-4">
                        {props.medicareCoverage.additionalDrugCoverageValue === 'yes' && <RequiredStar/>}
                        <label htmlFor="additionalDrugCoverageGroupNumber">Group number for this coverage</label>
                        <input type="text" className="form-control"
                               id="additionalDrugCoverageGroupNumber"
                               name="additionalDrugCoverageGroupNumber"
                               value={props.medicareCoverage.additionalDrugCoverageValue === 'yes' ? props.medicareCoverage.additionalDrugCoverageGroupNumber : ''}
                               onChange={props.handleCoverageChange}
                               disabled={props.medicareCoverage.additionalDrugCoverageValue !== 'yes'}
                               required={props.medicareCoverage.additionalDrugCoverageValue === 'yes'}/>
                    </div>
                </div>

                <div className="row form-row question">
                    <div className="col-sm-12 mt-3">
                        <div>
                            <h4>
                                Medicaid Information
                            </h4>
                            <hr/>
                            <p>
                                Are you enrolled in your state Medicaid program?
                            </p>
                            <YesNo name="dsnpEnrollmentValue" label="Medicaid Information"
                                   value={props.medicareCoverage.dsnpEnrollmentValue}
                                   onChange={props.handleCoverageChange}
                                   required="required"/>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-x-sm-12 col-lg-6">
                        {props.medicareCoverage.dsnpEnrollmentValue === 'yes' && <RequiredStar/>}
                        <label htmlFor="dsnpEnrollmentMedicaidNumber">If yes, please provide your Medicaid
                            number</label>
                        <input type="text" className="form-control"
                               id="dsnpEnrollmentMedicaidNumber" name="dsnpEnrollmentMedicaidNumber"
                               value={props.medicareCoverage.dsnpEnrollmentValue === 'yes' ? props.medicareCoverage.dsnpEnrollmentMedicaidNumber : ''}
                               onChange={props.handleCoverageChange}
                               disabled={props.medicareCoverage.dsnpEnrollmentValue !== 'yes'}
                               required={props.medicareCoverage.dsnpEnrollmentValue === 'yes'} pattern={"[0-9]+"}/>
                    </div>
                </div>
                
                <div className="row form-row question">
                    <div className="col-sm-12 mt-3">
                            <h4>
                                Primary Care Physician
                            </h4>
                            <hr/>
                            <p>
                                List your Primary Care Physician (PCP), or leave this section blank and we will assign one.
                            </p>
                            <label htmlFor="primaryCarePhysician" className={"w-100"}>Primary Care Physician
                            <input type="text" name="primaryCarePhysician" id="primaryCarePhysician" className="form-control"
                                   value={props.medicareCoverage.primaryCarePhysician}
                                   onChange={props.handleCoverageChange}/>
                            </label>
                    </div>
                </div>

                <NavFooter step={props.step}
                           lastStep={props.lastStep}
                           display={props.step === 2}
                           onChange={props.onChange}/>
                <FormID/>
            </form>
        </div>
    )
}