import React from "react";
import {formatMbi} from "../utilities/utilities";

function AlreadyInUse(props) {
    const display = (props.step === 7 ) ? '' : 'hidden';

    return <div className={`container ${display} no-print break-before`} id="alreadyInUse">
        <form method="post" id="formAlreadyInUse" name="formAlreadyInUse">
            <div className="row mt-5 mb-5">
                <div className="col-sm-12 text-center">
                    <h1 className="brand-color-red">Our Apologies</h1>
                    <h4 className={"brand-color-darkgray mt-4"}>The Medicare Number
                        <strong> {formatMbi(props.medicareCoverage.medicareNumber)}</strong> already exists in our system.
                    </h4>
                    <p>Please contact your agent for assistance or call Customer Support at 877-372-1033</p>

                    {props.soaForm.agentName &&       
                        <>    
                        <h5 className={"heading-medium brand-color-darkgray mt-4"}>Agent</h5>
                        <div>{props.soaForm.agentName}</div>
                        <div>{props.soaForm.agentPhone}</div>
                        <div>{props.soaForm.agentEmail}</div>
                        </>
                    }
                </div>
            </div>
            <hr/>
            <div className="row text-center mt-3">
                <div className="col-sm-10 offset-sm-1 mt-4">
                    <h3 className="brand-color-red">For more information:</h3>
                    <div className="row">
                        <div className={"col-sm-12"}>
                            <p>Visit <a href={"https://superiorselectinc.com/"} target={"_blank"} rel="noopener noreferrer">TributeMedicare.com</a> to access
                                our Provider Directory, Pharmacy Directory, and our Prescription Drug Formulary
                                (List of Covered Prescription Drugs).</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className={"col-sm-12"}>
                            <div className="heading-medium brand-color-darkgray">Claims Questions & Appeals Process</div>
                            <p>Member Services (877) 372-1033 (TTY call 711) 8 a.m. - 8 p.m., 7 days a week</p>
                            <p className={"brand-color-darkgray"}><b>1-800-MEDICARE</b> (800 633-4227), 24 hours a day, 7 days a week</p>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
}

export default AlreadyInUse;