import React from "react";
import {NavFooter} from "./NavFooter";
import {AlternateFormatButtons} from "./AlternateFormatButtons";
import {MaterialListButtons} from "./MaterialListButtons";
import {RequiredStar} from "./RequiredStar";
import {FormID} from "../FormID";

export function Optional(props) {
    const display = props.step === 4 ? '' : 'hidden';
    let hasPrinted = false;
    let sectionHeader = '';

    return (
        <div className={`container ${display} print break-before`} id="optional" style={{breakInside:'avoid-page'}}>
            <form method="post" id="formOptional" name="formOptional">

                <div className="row mt-4">
                    <div className="col">
                        <h1 className="brand-color-lightgray">Optional Information</h1>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-sm-12 mt-3">
                        <p>Answering these questions is optional. You will
                            not be denied coverage if you do not complete this section.</p>
                        <hr/>
                    </div>
                </div>
                <div className="row question">
                    <div className="col-sm-12">
                        <h4>
                            Language Selection
                        </h4>
                        <p>
                            Select if you want us to send you information in a language other than English.
                        </p>
                    </div>
                    <div className="col-sm-12">
                        <label htmlFor="language">
                            <input type="checkbox"
                                   name="language"
                                   id="language"
                                   value="spanish"
                                   checked={props.optionalInformation.language === 'spanish'}
                                   onChange={props.handleOptionalChange}
                            />
                            &nbsp; Espa&ntilde;ol
                        </label>
                    </div>
                </div>

                <div className="row question">
                    <div className="col-sm-12">
                        <h4>
                            Alternate Format
                        </h4>
                        <p>
                            Select one if you want us to send you information in an accessible format.
                        </p>
                        <AlternateFormatButtons name="AlternateFormat"
                                                handleOptionalChange={props.handleOptionalChange}
                                                optionalInformation={props.optionalInformation}/>
                        <p className="mt-3 text-muted">Please contact Tribute Health at 877-372-1033 if you need
                            information in an accessible format
                            other than what’s listed above. Our office hours are 8 a.m. to 8 p.m. 7 days a
                            week. TTY
                            users can call 711.</p>
                    </div>
                </div>

                <div className="row question">
                    <div className="col-sm-12">
                        <h4>
                            Request for Materials
                        </h4>
                        <p>
                            To get any of the following materials sent via email. Select one or more of the following.
                        </p>
                        <MaterialListButtons name="MaterialList"
                                             handleOptionalChange={props.handleOptionalChange}
                                             optionalInformation={props.optionalInformation}/>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-sm-12">
                        {
                            (props.optionalInformation.materialRequestEvidenceOfCoverage === 'yes' ||
                                props.optionalInformation.materialRequestListOfCoveredDrugs === 'yes' ||
                                props.optionalInformation.materialRequestListOfPharmacies === 'yes' ||
                                props.optionalInformation.materialRequestListOfProviders === 'yes') &&
                            <RequiredStar/>
                        }
                        <label htmlFor="enrolleeEmailAddress">Email Address</label>
                        <input type="email" className="form-control"
                               id="enrolleeEmailAddress"
                               name="enrolleeEmailAddress"
                               value={props.optionalInformation.enrolleeEmailAddress}
                               onChange={props.handleOptionalChange}
                               required={
                                   (props.optionalInformation.materialRequestEvidenceOfCoverage === 'yes' ||
                                       props.optionalInformation.materialRequestListOfCoveredDrugs === 'yes' ||
                                       props.optionalInformation.materialRequestListOfPharmacies === 'yes' ||
                                       props.optionalInformation.materialRequestListOfProviders === 'yes')
                               }
                        />
                    </div>
                </div>
                <div className="row question">
                    <div className="col-sm-12">
                        <h4>
                            Ethnicity and Race
                        </h4>
                        <p>
                            Select all that apply
                        </p>
                    </div>
                    <div className="col-sm-4 check-list">
                        <div className={"check-list-header"}>Ethnicity</div>
                        <div className={"check-list-body check-list-column-1"}>
                            {props.ethnicities &&
                                props.ethnicities.map((value, index) =>
                                    <div key={index} className={"check-list-item"}>
                                        <label htmlFor={value.value}>
                                            <input type="checkbox"
                                                   name={value.value}
                                                   id={value.value}
                                                   value={value.id}
                                                   checked={props.selectedEthnicities && props.selectedEthnicities.indexOf(value.id) !== -1}
                                                   onChange={props.handleEthnicityChange}
                                            />
                                            &nbsp;{value.value}
                                        </label>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="col-sm-8 check-list">
                        <div className={"check-list-header"}>Race</div>
                        <div className={"check-list-body check-list-column-2"}>
                            {props.races &&
                                props.races.map((value, index) => {
                                    let item;
                                    hasPrinted = sectionHeader === value.section;
                                    sectionHeader = value.section;
                                    if (sectionHeader === '') {
                                        item = <div key={index} className={"check-list-item"}>
                                            <label htmlFor={value.value}>
                                                <input type="checkbox"
                                                       name={value.value}
                                                       id={value.value}
                                                       value={value.id}
                                                       checked={props.selectedRaces && props.selectedRaces.indexOf(value.id) !== -1}
                                                       onChange={props.handleRaceChange}
                                                />
                                                &nbsp;{value.value}
                                            </label>
                                        </div>
                                    }
                                    else {
                                        item = <div key={index} className={"check-list-item"}>
                                            {!hasPrinted && <div style={{marginLeft: '-0.6rem', textDecoration: 'underline'}}>{sectionHeader}</div>}
                                            <div style={{paddingLeft: '1em'}}>
                                                <label htmlFor={value.value}>
                                                    <input type="checkbox"
                                                           name={value.value}
                                                           id={value.value}
                                                           value={value.id}
                                                           checked={props.selectedRaces && props.selectedRaces.indexOf(value.id) !== -1}
                                                           onChange={props.handleRaceChange}
                                                    />
                                                    &nbsp;{value.value}
                                                </label>
                                            </div>
                                        </div>
                                    }
                                    return item;
                                    }
                                )
                            }
                        </div>
                    </div>

                </div>

                <NavFooter step={props.step}
                           lastStep={props.lastStep}
                           display={props.step === 4}
                           onChange={props.onChange}/>
                <FormID/>
            </form>
        </div>
    )
}