import React from 'react';
import {Container, Navbar, NavbarBrand} from 'reactstrap';
import {Link} from 'react-router-dom';
import '../custom.css';
import logo from '../assets/TributeWebHeader.png';
import {CustomerSupport} from "./CustomerSupport";

export function NavMenu(props) {

    const activeCrumb = (step, id) => {
        return step === id ? 'breadcrumb-item active' : 'breadcrumb-item';
    }
    
    const breadcrumbs = <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
            <li className={activeCrumb(props.step, 1)} aria-current="page">Application</li>
            <li className={activeCrumb(props.step, 2)} aria-current="page">Coverage</li>
            <li className={activeCrumb(props.step, 3)} aria-current="page">Premium</li>
            <li className={activeCrumb(props.step, 4)} aria-current="page">Optional</li>
            <li className={activeCrumb(props.step, 5)} aria-current="page">Signature</li>
        </ol>
    </nav>
    
    return (
        <header data-html2canvas-ignore="true">
            <Navbar className="ng-white border-bottom box-shadow mb-3 header-container" light style={{backgroundColor: '#dfdfdf4a'}}>
                <Container>
                    <NavbarBrand tag={Link} to="/" className="logo">
                        <img src={logo} alt="Tribute Health Plans"/>
                    </NavbarBrand>
                    <CustomerSupport/>
                    {props.step > 0 && breadcrumbs}
                </Container>
            </Navbar>
        </header>
    );
}
