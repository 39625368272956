export function removeDashes(inputString) {
    return inputString.replace(/\D+/g, '');
}

export function formatPhone(phone) {
    const re = /(\d{3})(\d{3})(\d{4})/;
    if (typeof phone === 'string') {
        return phone.replace(re, '($1) $2-$3');
    }
    return phone;
}

export function getTodayDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); //January is 0
    const yyyy = today.getFullYear();
    return yyyy + "-" + month + "-" + day;
}

export function formatMbi(value) {
    const re = /([A-Z0-9]{4})([A-Z0-9]{3})([A-Z0-9]{4})/;
    if (typeof value === 'string') {
        return value.replace(re, '$1-$2-$3');
    }
    return value;
}

    
    