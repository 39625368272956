import React from 'react';
import {NavFooter} from "../NavFooter";
import './SelfDirected.css';

export function SelfDirected(props) {
    const display = props.step === -1 && !props.loadError ? '' : 'hidden';

    return <form className={`${display} no-print`} id="selfDirected" data-html2canvas-ignore="true">
        <div className={"container"} style={{minHeight:'60vh'}}>
            <div className={"row mt-4"}>
                <div className={"col-sm-10 offset-1 text-center"}>
                    <fieldset className={"mb-2"}>
                        <legend className={"mb-2"}>
                            <h2 className={"mb-0"}>
                                By proceeding to the next screen, you affirm that you understand this online enrollment utility is for self-directed enrollment into a Medicare Advantage plan and is completed for your personal enrollment or for an individual for whom you are an authorized Power of Attorney.
                            </h2>
                        </legend>
                    </fieldset>

                    <NavFooter display={true}
                               step={props.step}
                               lastStep={props.lastStep}
                               onChange={props.onChange}
                               onFormSubmit={props.onFormSubmit}
                               disabled={false}/>
                </div>
            </div>
        </div>
    </form>
}