import React from "react";

export function MaterialListButtons(props) {
    return (
        <div className="btn-group btn-group-toggle flex-wrap"
             data-toggle="buttons" role="group"
             aria-label={props.label || props.name}>

            <label className={"btn btn-secondary " + (props.optionalInformation.materialRequestEvidenceOfCoverage === 'yes' ? 'active' : '')}>
                <input type="checkbox"
                       name="materialRequestEvidenceOfCoverage"
                       id="materialRequestEvidenceOfCoverage" 
                       value='yes' 
                       checked={props.optionalInformation.materialRequestEvidenceOfCoverage === 'yes' }
                       onChange={props.handleOptionalChange}
                       data-html2canvas-ignore="true"/>
                Evidence of Coverage</label>

            <label className={"btn btn-secondary " + (props.optionalInformation.materialRequestListOfProviders === 'yes' ? 'active' : '')}>
                <input type="checkbox"
                       name="materialRequestListOfProviders"
                       id="materialRequestListOfProviders" 
                       value='yes'
                       checked={props.optionalInformation.materialRequestListOfProviders === 'yes'} 
                       onChange={props.handleOptionalChange}
                       data-html2canvas-ignore="true"/>
                List of Providers</label>

            <label className={"btn btn-secondary " + (props.optionalInformation.materialRequestListOfPharmacies === 'yes' ? 'active' : '')}>
                <input type="checkbox"
                       name="materialRequestListOfPharmacies"
                       id="materialRequestListOfPharmacies" 
                       value='yes'
                       checked={props.optionalInformation.materialRequestListOfPharmacies === 'yes'}
                       onChange={props.handleOptionalChange}
                       data-html2canvas-ignore="true"/>
                List of Pharmacies</label>
            
            <label className={"btn btn-secondary " + (props.optionalInformation.materialRequestListOfCoveredDrugs === 'yes' ? 'active' : '')}>
                <input type="checkbox"
                       name="materialRequestListOfCoveredDrugs"
                       id="materialRequestListOfCoveredDrugs" 
                       value='yes'
                       checked={props.optionalInformation.materialRequestListOfCoveredDrugs === 'yes'} 
                       onChange={props.handleOptionalChange}
                       data-html2canvas-ignore="true"/>
                List of covered drugs</label>
        </div>
    );
}