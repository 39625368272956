import React, {useState, useEffect} from "react";
import {TextInput} from "./TextInput";
import {StateOrProvince} from "./StateOrProvince";
import {GenderSelect} from "./GenderSelect";
import {removeDashes} from "../utilities/utilities";

export function MemberIdentification(props) {
    const [mailingAddressRequired, setMailingAddressRequired] = useState('');

    function validateZipOrPostalCode(e) {
        const value = e.target.value;
        const el = document.getElementById(e.target.id);
        if (/^\d*$/.test(value)) {
            el.setCustomValidity('');
            props.onChange(e);
        } else {
            el.setCustomValidity('The Zip Code must contain only numbers.');
        }
    }

    const handlePhoneValidation = ({target}) => {
        const id = target.id;
        const value = removeDashes(target.value);
        const el = document.getElementById(id);
        if (value.length !== 10) {
            el.setCustomValidity('Phone numbers must be 10 digits');
        } else {
            el.setCustomValidity('');
        }
    }

    useEffect(() => {
        const value = !(props.mailingAddress.address1 === ''
            && props.mailingAddress.city === ''
            && props.mailingAddress.stateOrProvince === ''
            && props.mailingAddress.zipPostalCode === ''
            && props.mailingAddress.county === '')
            ? 'required'
            : '';
        setMailingAddressRequired(value);
    }, [props.mailingAddress])

    return (
        <>
            <fieldset id={"memberInfo"} disabled={props.disabled}>
            <div className="form-row mt-2">
                <div className="form-group col-sm-4">
                    <TextInput label="First Name"
                               name="firstName"
                               id="firstName"
                               type="text"
                               required="required"
                               value={props.member.firstName} onChange={props.onChange}/>
                </div>
                <div className="form-group col-sm-5">
                    <TextInput label="Last Name" name="lastName" id="lastName" type="text" required="required"
                               value={props.member.lastName} onChange={props.onChange}/>
                </div>
                <div className="form-group col-sm-3">
                    <TextInput label="MI" name="middleInitial" id="middleInitial" type="text"
                               value={props.member.middleInitial} onChange={props.onChange} maxLength={1}/>
                </div>
            </div>
            <div className="form-row mt-2">
                <div className="form-group col-sm-12 col-md-4">
                    <TextInput label="Date of Birth" name="dateOfBirth" id="dateOfBirth" type="date" required="required"
                               value={props.member.dateOfBirth} onChange={props.onChange}/>
                </div>
                <div className="form-group col-sm-12 col-md-5">
                    <TextInput label="Home Phone Number" name="phone" id="phone" type="tel" required="required"
                               value={props.member.phone} onChange={props.onChange}
                               onBlur={handlePhoneValidation}/>
                </div>
                <div className="form-group col">
                    <div className={"text-strong pb-1"}>
                        <span className="required no-print">*</span>
                        Gender
                    </div>
                    <GenderSelect name={"gender"} label={"Gender"}
                                  value={props.member.gender}
                                  onChange={props.onChange}
                                  required={"required"}/>
                </div>
            </div>
            <div className="form-row mt-2">
                <div className="form-group col-sm-12">
                    <TextInput label="Permanent Residence street address (PO Box NOT allowed)"
                               name="location.address1" id="address1"
                               type="text" required="required"
                               value={props.memberAddress.address1} onChange={props.onChange}/>
                </div>
            </div>
            <div className="form-row mt-2">
                <div className="form-group col-x-sm-12 col-md-4">
                    <TextInput label="City" type="text" required="required"
                               name="location.city" id="city"
                               value={props.memberAddress.city} onChange={props.onChange}/>
                </div>

                <div className="col-x-sm-12 col-md-4">
                    <TextInput label="County" name="location.county" id="county"
                               type="text" required="required"
                               value={props.memberAddress.county} onChange={props.onChange}/>
                </div>

                <div className="col-x-sm-12 col-md-2">
                    <StateOrProvince name="location.stateOrProvince"
                                     id="stateOrProvince"
                                     value={props.memberAddress.stateOrProvince}
                                     onChange={props.onChange}
                                     required="required"
                                     requiredValue={"AR"}/>
                </div>
                <div className="col-x-sm-12 col-md-2">
                    <TextInput label="ZIP Code"
                               type="text"
                               required="required"
                               name="location.zipPostalCode"
                               id="zipPostalCode"
                               value={props.memberAddress.zipPostalCode}
                               onChange={validateZipOrPostalCode}
                               minLength={5}
                               maxLength={5}/>
                </div>
            </div>
            <div className={"form-row mt-2"} data-html2canvas-ignore="true">
                <div className={"col mt-3 mb-4"}>
                    <hr/>
                </div>
            </div>
            <div className="form-row mt-2">
                <div className="form-group col-sm-12">
                    <TextInput label="Mailing address, if different from your permanent address (PO Box allowed)"
                               name="location2.address1" id="address2" type="text"
                               value={props.mailingAddress.address1}
                               onChange={props.onChange}
                               required={mailingAddressRequired}/>
                </div>
            </div>
            <div className="form-row mt-2">
                <div className="col-sm-4">
                    <TextInput label="City"
                               name="location2.city"
                               id="city2"
                               type="text"
                               value={props.mailingAddress.city}
                               onChange={props.onChange}
                               required={mailingAddressRequired}/>
                </div>
                <div className="col-sm-4">
                    <TextInput label="County"
                               name="location2.county"
                               id="county2"
                               type="text"
                               value={props.mailingAddress.county}
                               onChange={props.onChange}
                               required={mailingAddressRequired}/>
                </div>
                <div className="col-sm-2">
                    <StateOrProvince name="location2.stateOrProvince"
                                     value={props.mailingAddress.stateOrProvince}
                                     onChange={props.onChange}
                                     id="stateOrProvince2"
                                     required={mailingAddressRequired}/>
                </div>
                <div className="col-sm-2">
                    <TextInput label="ZIP Code"
                               name="location2.zipPostalCode"
                               id="zipPostalCode2"
                               type="text"
                               value={props.mailingAddress.zipPostalCode}
                               onChange={validateZipOrPostalCode}
                               minLength={5}
                               maxLength={5}
                               required={mailingAddressRequired}/>
                </div>
            </div>
            </fieldset>
        </>
    );
}