import {SOAQualification} from "../components/SOAForm/SOAQualification";

export const screenModel = {
    medicareYesNo: '',
    medicaidYesNo: '',
    facilityYesNo: '',
    homeYesNo: '',
    facilityId: '',
    facilityName: '',
}

export const memberModel = {
    firstName: '',
    lastName: '',
    middleInitial: '',
    dateOfBirth: '',
    gender: '',
    facility: '',
    phone: ''
}

export const locationModel = {
    address1: '',
    address2: '',
    address3: '',
    city: '',
    county: '',
    emailAddress: '',
    stateOrProvince: '',
    zipPostalCode: ''
}

export const coverageModel = {
    medicareNumber: '',
    additionalDrugCoverageValue: '',
    additionalDrugCoverageName: '',
    additionalDrugCoverageMemberNumber: '',
    additionalDrugCoverageGroupNumber: '',
    dsnpEnrollmentValue: '',
    dsnpEnrollmentMedicaidNumber: '',
    primaryCarePhysician: ''
}

export const premiumModel = {
    premiumPaymentOptionId: 1,
    mailingAddressIsFacilityOrPoa: ''
}

export const optionalModel = {
    language: '',
    accessibleFormat: '',
    materialRequestEvidenceOfCoverage: '',
    materialRequestListOfPharmacies: '',
    materialRequestListOfCoveredDrugs: '',
    materialRequestListOfProviders: '',
    enrolleeEmailAddress: '',
}

export const confirmationModel = {
    acknowledgment: false,
    signature1: '',
    signature2: '',
    signatureDate: '',
    receiptDate: '',
    userRole: ''
}

export const authorizedRepresentativeModel = {
    lastName: "",
    firstName: "",
    middleInitial: "",
    address1: "",
    city: "",
    stateOrProvince: "",
    zipPostalCode: "",
    phoneNumber: "",
    relationshipToMember: ""
}

export const soaFormModel = {
    agentNpn: '',
    agentName: '',
    agentPhone: '',
    agentEmail: '',
    initialContact: '',
    productToDiscuss: "",
    firstNameAuthRep: '',
    lastNameAuthRep: '',
    relationshipToMember: '',
    signatureAuthRep: '',
    signatureAuthRepConfirm: '',
    signatureAuthRepDate: '',
    signatureMember: '',
    signatureMemberConfirm: '',
    signatureMemberDate: '',
    signature: '',
    signatureConfirm: '',
    signatureDate: '',
    acknowledgment: '',
}

export const agentModel = {
    agentId: '',
    agentName: '',
    agentPhone: '',
    agentEmail: '',
}

export const pageModel = {
    soaQualification: -2,
    soaForm: -1,
    screen: 0,
    application: 1,
    coverage: 2,
    premium: 3,
    optional: 4,
    confirmation: 5,
    complete: 6,
}

export const ethnicityModel = {
    id: 0,
    value: '',
    dtrrPosition: 0
}

export const raceModel = {
    id: 0,
    value: '',
    dtrrPosition: 0,
    sortOrder: 0,
    section: '',
}